import {
  Box,
} from "@mui/material";
import React from "react";
import { LoginForm } from "./LoginForm";
import { useLogin } from "./useLogin";

 function SignIn() {
   const loginFormProps = useLogin();

   return (
     <Box
       display="flex"
       justifyContent="center"
       alignItems="center"
       minHeight="100vh"
     >
       <LoginForm {...loginFormProps} />
     </Box>
   );
 }

export default SignIn;
