export type PropertyFilterFn<T> = (obj: T) => boolean;
export type PropertyCompareFn<T> = (a: T, b: T) => number;

export interface PropertyShapingState<T> {
  filterBy: (value: string) => PropertyFilterFn<T>
  sortBy: PropertyCompareFn<T>
}

export type TableDataConfig<T> = Partial<Record<string, PropertyShapingState<T>>>

export function regexTest(searchValue: string, source: string): boolean {
  return (new RegExp(searchValue, "i")).test(source);
}

export function numberCompare(a: number | null | undefined, b: number | null | undefined): number {
  const missA = !a;
  const missB = !b;

  if (missA && missB) {
    return 0;
  }
  else if (missA && !missB) {
    return -1;
  }
  else if (!missA && missB) {
    return 1;
  }
  else if (!missA && !missB) {
    return a - b;
  }

  return 0;
}

export function stringCompare(a: string | null | undefined, b: string | null | undefined): number {
  const missA = a == null || (typeof a === "undefined");
  const missB = b == null || (typeof b === "undefined");

  console.log(a + " | " + b)
  console.log(missA + " | " + missB)
  if (missA && missB) {
    return 0;
  }
  else if (missA && !missB) {
    return -1;
  }
  else if (!missA && missB) {
    return 1;
  }
  else if (!missA && !missB) {
    console.log(a.localeCompare(b))
    return a.localeCompare(b);
  }

  return 0;
}