import { ApiResourceTableColumnFactory } from "../../../hooks/useApiResourceTable";
import React from "react";
import { ProductVersionDto } from "../../../models/domain/ProductVersionDto";
import { MrtDateTimeField } from "../../mrt/mrt-datetime/MrtDateTimeField";
import { MrtProductVersionStatusField } from "../../mrt/mrt-version-channel/MrtProductVersionStatusField";

export const FarmLinkDialogProductVersionColumnsFactory: ApiResourceTableColumnFactory<ProductVersionDto> = () => {
  return [
    {
      accessorKey: 'name',
      header: 'Name',
    },
    {
      accessorKey: 'pipelineBuildTimestamp',
      header: 'Built At',
      Cell: ({cell}) => <MrtDateTimeField cell={cell}/>
    },
    {
      accessorKey: "productVersionStatus",
      header: 'Status',
      Cell: ({cell}) => <MrtProductVersionStatusField cell={cell}/>
    },
  ];
}