import { MrtBaseFieldProps } from "../mrt-base/mrt-base";
import { Typography } from "@mui/material";
import React from "react";
import {ProductVersionFileKeyDto} from "../../../models/domain/enums";
import {ProductVersionFileKeyNames} from "../../../models/domain-enum-mapping";

export type MrtProductVersionFileKeyFieldProps<T extends Record<string, any>> = MrtBaseFieldProps<T>

export const MrtProductVersionFileKeyField = <T extends Record<string, any>,>({cell, naValue} : MrtProductVersionFileKeyFieldProps<T>) => {
  const value = cell.getValue<ProductVersionFileKeyDto>();
  const name = typeof(value) !== "undefined" && value in ProductVersionFileKeyDto ? ProductVersionFileKeyNames[value] : null;
  return (
    <Typography>{ name || naValue || ''}</Typography>
  );
}