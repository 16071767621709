import {
  Alert,
  Box, BoxProps,
  Collapse,
  Divider,
  LinearProgress,
  Paper,
  Stack,
  Toolbar,
  Typography
} from "@mui/material";
import React from "react";
import { UseQueryResult } from "@tanstack/react-query";

export type AppFormOnlyQueryIntrinsicProps<TVariables extends {}> =
  {
    primaryQuery: UseQueryResult<TVariables>;
  };

export type AppFormOnlyQueryDerivedProps =
  Pick<BoxProps, "sx">;

export type AppFormOnlyQueryBasicProps<TFormData extends {}, ExtraProps extends {} = {}> =
  AppFormOnlyQueryIntrinsicProps<TFormData> &
  AppFormOnlyQueryDerivedProps &
  {
    formLabel?: string;
    showError?: boolean;
    showFetching?: boolean;
    controls?: {
      extra?: React.ReactNode;
    }
  } &
  ExtraProps;

export type AppFormOnlyQueryProps<TFormData extends {}, ExtraProps extends {} = {}> =
  & AppFormOnlyQueryBasicProps<TFormData>
  & {
    children?: React.ReactNode;
  }
  & ExtraProps;

export const AppFormOnlyQuery = <TFormData extends {}, ExtraProps extends {} = {}>
(props: AppFormOnlyQueryProps<TFormData, ExtraProps>) => {
  const {
    primaryQuery,
    formLabel,
    showError,
    showFetching,
    controls,
    children,
    sx,
  } = props;

  return (
    <Box sx={sx}>
      <Collapse in={showError && primaryQuery.isError}>
        <Alert color="error" icon={false}>
          <Typography>Error occurred</Typography>
        </Alert>
      </Collapse>

      <Stack>
        {
          showFetching && primaryQuery.isFetching ? <LinearProgress color="inherit"/> : null
        }

        <Paper elevation={2}>
          <Box
            sx={{
              '& .MuiTextField-root': {m: 1, width: '50ch'},
              '& .MuiFormControl-root': {m: 1, width: '50ch'},
              '& fieldset': {border: 0},
            }}
          >
            <form noValidate onSubmit={event => event.preventDefault()}>
              <Box sx={{pt: 1, pb: 1}}>
                <Toolbar sx={{background: "inherit"}} variant="dense">
                  <Typography component="h1" variant="h6" fontWeight="lighter" minWidth="230px">
                    {formLabel}
                  </Typography>
                  {(controls?.extra) && <Divider orientation="vertical" flexItem sx={{ml: 2, mr: 2}}/>}
                  {controls?.extra}
                </Toolbar>
              </Box>
              <Divider/>

              {children}
            </form>
          </Box>
        </Paper>
      </Stack>
    </Box>
  );
};