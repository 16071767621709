import farmsCardImage from '../../img/cards-farm.jpg'
import { AdminAppRoutePaths } from "../../routes";
import { Card, CardMedia, CardContent, CardActions, Typography } from "@mui/material";
import { LinkButton } from "../../comps/link-button/LinkButton";

const StartPage = () => {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        sx={{ height: 140 }}
        image={farmsCardImage}
        title="Manage farms"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Farms
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Review and manage farms
        </Typography>
      </CardContent>
      <CardActions>
        <LinkButton to={AdminAppRoutePaths.farms()}>
          Open Farms
        </LinkButton>
      </CardActions>
    </Card>
  );
}

export default StartPage;
