import { MrtBaseFieldProps } from "../mrt-base/mrt-base";
import React from "react";
import IconButton from "@mui/material/IconButton";
import {Download} from "@mui/icons-material";

export type MrtDownloadButtonFieldProps<T extends Record<string, any>> = MrtBaseFieldProps<T> & {
  fileName: string;
}

export const MrtDownloadButtonField = <T extends Record<string, any>,>({cell, fileName} : MrtDownloadButtonFieldProps<T>) => {
  const value = cell.getValue<string>();
  return (
    <IconButton href={value} target="_blank" rel="noopener" download={fileName}>
      <Download/>
    </IconButton>
  );
}