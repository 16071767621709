import { PageInfoDto } from "../../models/domain/pagination/PageInfoDto";
import { TableDataShaping, TableFilters, TablePagination, TableSorting } from "./TableModels";
import { PropertyCompareFn, TableDataConfig } from "./TableDataConfig";
import { useCallback, useMemo } from "react";

export type TableDataShaper<T> = (input: PageInfoDto<T>) => PageInfoDto<T>

type FilterFn<T> = (item: T) => boolean;

type ItemComparerFn<T> = (a: T, b: T) => number;

export function useDataShaper<T>(config: TableDataConfig<T>, shaping: TableDataShaping): TableDataShaper<T>
{
  const filters: TableFilters = shaping;
  const sorting: TableSorting = shaping;
  const pagination: TablePagination = shaping;

  if (!!pagination && Boolean(pagination.pageIndex) && !Boolean(pagination.pageSize)) {
    throw new Error("Invalid pagination")
  }

  const filterFns: FilterFn<T>[] = useMemo(() => {
    const resultFns: FilterFn<T>[] = [];

    const globalFilter = filters?.globalFilter ?? null;

    if (globalFilter != null) {
      const globalFilterFns: FilterFn<T>[] = [];

      for (const modelKey in config) {
        const propertyConfig = config[modelKey];

        if (!!propertyConfig) {
          globalFilterFns.push(propertyConfig.filterBy(globalFilter))
        }
      }

      if (globalFilterFns.length > 0) {
        resultFns.push(item => {
          for (const globalFilterFn of globalFilterFns) {
            if (globalFilterFn(item)) {
              return true;
            }
          }
          return false;
        })
      }
    }

    const columnFilters = filters?.columnFilters ?? [];

    if (columnFilters != null) {
      for (const columnFilter of columnFilters) {
        if (config.hasOwnProperty(columnFilter.id) && typeof columnFilter.value === "string" && !!columnFilter.value) {
          const propertyConfig = config[columnFilter.id];

          if (!!propertyConfig) {
            resultFns.push(propertyConfig.filterBy(columnFilter.value))
          }
        }
      }
    }

    return resultFns;
  }, [config, filters?.columnFilters, filters?.globalFilter]);

  const comparerFn: ItemComparerFn<T> | null = useMemo(() => {
    const columnSortings = sorting?.sorting ?? [];

    if (columnSortings.length === 0) {
      return null;
    }

    const propertyCompareFns: PropertyCompareFn<T>[] = [];

    for (const columnSorting of columnSortings) {
      if (config.hasOwnProperty(columnSorting.id)) {
        const propertyConfig = config[columnSorting.id];

        if (!!propertyConfig) {
          propertyCompareFns.push(columnSorting.desc ? (a, b) => -(propertyConfig.sortBy(a,b)) : propertyConfig.sortBy)
        }
      }
    }

    return (a, b) => {
      for (const propertyCompareFn of propertyCompareFns) {
        const result = propertyCompareFn(a, b);

        if (result !== 0) {
          return result;
        }
      }

      return 0;
    }
  }, [config, sorting?.sorting]);

  const pageIndex = pagination?.pageIndex ?? null;
  const pageSize = pagination?.pageSize ?? null;

  return useCallback(input => {
    const totalCount = input.items.length;
    const fetchPage = pageIndex ?? 0;
    const fetchPageSize = pageSize ?? totalCount;

    let filteredItems = input.items.filter(item => {
      for (const filterFn of filterFns) {
        if (!filterFn(item)) {
          return false;
        }
      }
      return true;
    });

    if (comparerFn != null) {
      filteredItems = filteredItems.sort(comparerFn);
    }

    const startIndex = fetchPage * fetchPageSize;
    const endIndex = (fetchPage + 1) * fetchPageSize;

    const resultItems = filteredItems.slice(startIndex, endIndex);

    return {
      items: resultItems,
      totalCount: filteredItems.length,
      currentPage: fetchPage,
      pageSize: fetchPageSize,
      hasPreviousPage: startIndex > 0,
      hasNextPage: endIndex < totalCount - 1
    };
  }, [pageIndex, pageSize, comparerFn, filterFns]);
}

