import React from 'react';
import {
  Box, Paper,
} from "@mui/material";
import { ApiResourceTable, ApiResourceTableProps } from "../../../comps/api-resource-table/ApiResourceTable";
import { UpdateGroupFarmDto } from "../../../models/domain/UpdateGroupFarmDto";

export const UpdateGroupFarmsForm = (props: ApiResourceTableProps<UpdateGroupFarmDto>) => {
  return (
    <Paper elevation={2} sx={{mt: 2}}>
      <Box sx={{mt: 1}}>
        <ApiResourceTable {...props} />
      </Box>
    </Paper>
  );
}
