import { useState } from "react";

export type UseFormState = {
  isChangeAllowed: boolean;
  setIsChangeAllowed: (value: boolean) => void;
  isControlBlocked: boolean;
  setIsControlBlocked: (value: boolean) => void;
}

export const useForm = (initialIsChangeAllowed?: boolean): UseFormState => {
  const [isChangeAllowed, setIsChangeAllowed] = useState(!!initialIsChangeAllowed ? initialIsChangeAllowed : false);
  const [isControlBlocked, setIsControlBlocked] = useState(false);

  return {
    isChangeAllowed,
    setIsChangeAllowed,
    isControlBlocked,
    setIsControlBlocked,
  };
}