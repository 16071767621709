import React from 'react';
import { Box, Divider, Stack, Toolbar, Typography } from "@mui/material";
import { useNewVersionSourceHostProfile } from "./useNewVersionSourceHostProfile";
import { NewVersionSourceHostProfileForm } from "./NewVersionSourceHostProfileForm";
import { VersionSourceHostIcon } from "../../../comps/icons/VersionSourceHostIcon";
import { AdminAppRoutePaths } from "../../../routes";
import { LinkButton } from "../../../comps/link-button/LinkButton";
import { ArrowBack } from "@mui/icons-material";

const VersionSourceHostNewItemPage = () => {
  const versionSourceHostProfile = useNewVersionSourceHostProfile();

  return (
    <Stack>
      <Box>
        <Toolbar variant="dense">
          <LinkButton variant="text" to={AdminAppRoutePaths.versionSourceHosts()} sx={{ml: 0}}>
            <ArrowBack/>
          </LinkButton>

          <Divider orientation="vertical" sx={{ ml: 2, mr: 2 }} flexItem />

          <Typography sx={{ mr: 1 }}>
            <VersionSourceHostIcon fontSize="large"/>
          </Typography>
          <Typography component="h1" variant="h5" color="inherit">
            New Version Source Host
          </Typography>

          <Divider orientation="vertical" sx={{ ml: 2, mr: 2 }} flexItem />
        </Toolbar>
      </Box>

      <Divider sx={{ mt: 2, mb: 2 }} />

      <NewVersionSourceHostProfileForm {...versionSourceHostProfile}/>

    </Stack>
  );
}

export default VersionSourceHostNewItemPage;
