import { FarmLinkKindDto } from "./models/domain/enums";

export type ApiResourceType =
  | "user"
  | "farms"
  | "productVersions"
  | "productVersionFiles"
  | "updateGroups"
  | "versionSources"
  | "versionSourceHosts"
  | "farmLinks"
  | "updateGroupProductVersions"
  | "updateGroupUsers"
  | "updateGroupFarms"
  | "liveVersionSources"
  | "allFarms";

const resourceFn = (host: string | null, path: string, id?: number | string | null, suffix?: string | null) => {
  const hostPrefix = host == null ? "" : host.endsWith("/") ? host : host + "/";
  const resourcePath = !!id ? `api/${path}/${id}` : `api/${path}`
  const subResource = !!suffix ? "/" + suffix : "";
  return hostPrefix + resourcePath + subResource;
};

export type ApiResourceFn = (host?: string | null, ...args: any[]) => string;

export const ApiResourcePaths: Record<ApiResourceType, ApiResourceFn> = {
  user: (host: string | null = null, sub: string | null = null) => resourceFn(host, "user", sub),
  farms: (host: string | null = null, id: number | null = null) => resourceFn(host, "farms", id),
  productVersions: (host: string | null = null, id: number | null = null, suffix: string | null = null) => resourceFn(host, "productVersions", id, suffix),
  updateGroups: (host: string | null = null, id: number | null = null) => resourceFn(host, "updateGroups", id),
  versionSources: (host: string | null = null, id: number | null = null) => resourceFn(host, "versionSources", id),
  versionSourceHosts: (host: string | null = null, id: number | null = null) => resourceFn(host, "versionSourceHosts", id),

  productVersionFiles: (host: string | null = null, id: number, fileId: number | null = null) => {
    const rootUrl = resourceFn(host, "productVersions", id);
    const fileIdSuffix = !!fileId ? `/${fileId}` : "";
    return `${rootUrl}/files${fileIdSuffix}`;
  },

  farmLinks: (host: string | null = null, id: number, kind: FarmLinkKindDto = FarmLinkKindDto.UpdateGroup, linkId: number | null = null) => {
    const rootUrl = resourceFn(host, "farms", id);
    const linkIdSuffix = !!linkId ? `/${kind}:${linkId}` : "";
    return `${rootUrl}/links${linkIdSuffix}`;
  },

  updateGroupProductVersions: (host: string | null = null, id: number, productVersionId: number | null = null) => {
    const rootUrl = resourceFn(host, "updateGroups", id);
    const productVersionIdSuffix = !!productVersionId ? `/${productVersionId}` : "";
    return `${rootUrl}/productVersions${productVersionIdSuffix}`;
  },

  updateGroupUsers: (host: string | null = null, id: number) => {
    const rootUrl = resourceFn(host, "updateGroups", id);
    return `${rootUrl}/users`;
  },

  updateGroupFarms: (host: string | null = null, id: number) => {
    const rootUrl = resourceFn(host, "updateGroups", id);
    return `${rootUrl}/farms`;
  },

  liveVersionSources: (host: string | null = null, versionSourceHostId: number) => {
    const rootUrl = resourceFn(host, "versionSourceHosts", versionSourceHostId);
    return `${rootUrl}/liveSources`;
  },

  allFarms: (host: string | null = null) => resourceFn(host, "farms-all"),
}