import { PageInfoDto } from "../../models/domain/pagination/PageInfoDto";
import { TableDataShaper } from "./useDataShaper";

export const getShapedData: <T>(input: PageInfoDto<T> | undefined, shaper: TableDataShaper<T>) => PageInfoDto<T> =
  (input, shaper) => {
    if (!input || !input.items || input.items.length === 0) {
      return {
        items: [],
        totalCount: 0,
        currentPage: 0,
        hasNextPage: false,
        hasPreviousPage: false,
        pageSize: 1
      }
    }

    return shaper(input);
  }