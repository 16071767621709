import { UpdateGroupUserDto } from "../../../models/domain/UpdateGroupUserDto";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuthContext } from "../../../hooks/useAuth";
import Constants from "../../../constants";
import axios from "axios";
import { ApiResourcePaths } from "../../../apiRoutes";
import React from "react";
import { UpdateGroupAccessLevelDto } from "../../../models/domain/enums";
import { SelectChangeEvent } from "@mui/material";
import { UpdateGroupDto } from "../../../models/domain/UpdateGroupDto";

export type UseUserAccessLevelSelectState = {
  onChange: (event: SelectChangeEvent<UpdateGroupAccessLevelDto>) => void;
}

export const useUserAccessLevelSelect = (updateGroupUser: UpdateGroupUserDto): UseUserAccessLevelSelectState => {
  const queryClient = useQueryClient();
  const {authHeader} = useAuthContext();

  const mutation = useMutation({
    mutationFn: async (accessLevel: UpdateGroupAccessLevelDto): Promise<UpdateGroupDto> => {
      const apiUrl = Constants.BACKEND_URL;
      return (await axios.put(ApiResourcePaths.updateGroupUsers(apiUrl, updateGroupUser.updateGroupId), {
        UserName: updateGroupUser.userName,
        AccessLevel: accessLevel
      }, {headers: authHeader})).data;
    },
    onSuccess: _ => {
      queryClient.invalidateQueries({
        queryKey: [ApiResourcePaths.updateGroupUsers(null, updateGroupUser.updateGroupId)]
      });
    }
  });

  const onChange = React.useCallback(async (event: SelectChangeEvent<UpdateGroupAccessLevelDto>) => {
    await mutation.mutateAsync(event.target.value as UpdateGroupAccessLevelDto);
  }, [mutation]);

  return {
    onChange
  }
}