import {
  Autocomplete, CircularProgress,
  TextField,
} from "@mui/material";
import React from "react";
import { AppForm, AppFormProps } from "../../../core/form/AppForm";
import {
  NewVersionSourceDto,
  NewVersionSourceProfileExtraProps
} from "./useNewVersionSourceProfile";
import { VersionSourceDto } from "../../../models/domain/VersionSourceDto";

export const NewVersionSourceProfileForm = (props: AppFormProps<NewVersionSourceDto, VersionSourceDto, NewVersionSourceProfileExtraProps>) => {
  const {
    versionSourceHostsQuery: {data: versionSourceHosts, isFetching: isVersionsSourceHostsFetching},
    liveVersionSourcesQuery: {data: liveSources, isFetching: isLiveVersionSourcesFetching},
    setSelectedHostId
  } = props;

  return (
    <AppForm {...props} formLabel="Version Source Profile" controls={{showEdit: false, showCancel: false}}>
      {({values, handleBlur, touched, errors, setFieldValue}) => (
        <>
          <fieldset>
            <Autocomplete<number>
              id="versionSourceHostId"
              options={versionSourceHosts?.data?.items?.map(x => x.id) ?? []}
              getOptionLabel={option => {
                return versionSourceHosts?.itemsMap && versionSourceHosts?.itemsMap[option]
                  ? versionSourceHosts?.itemsMap[option].name
                  : "N/A";
              }}
              value={values.versionSourceHostId}
              onChange={(_, value) => {
                setFieldValue("versionSourceHostId", value);
                setSelectedHostId(value);
              }}
              onBlur={handleBlur}
              renderInput={(params) =>
                <TextField {...params}
                           variant="standard"
                           label="Version Source Host"
                           onBlur={handleBlur}
                           onKeyDown={event => event.preventDefault()}
                           error={touched.versionSourceHostId && Boolean(errors.versionSourceHostId)}
                           helperText={touched.versionSourceHostId && errors.versionSourceHostId}
                />
              }

            />
            {isVersionsSourceHostsFetching && <CircularProgress sx={{ml: 2}}/>}
          </fieldset>
          <fieldset>
            <Autocomplete
              id="sourcePath"
              options={liveSources?.data?.items.map(x => x.path) ?? []}
              getOptionDisabled={option => liveSources?.itemsMap && liveSources?.itemsMap[option] ? !!liveSources?.itemsMap[option].versionSourceId : false}
              getOptionLabel={option => option || "N/A"}
              value={values.sourcePath}
              onChange={(_, value) => {
                setFieldValue("sourcePath", value);
              }}
              onBlur={handleBlur}
              renderInput={(params) =>
                <TextField {...params}
                           variant="standard"
                           label="Live Version Sources"
                           onBlur={handleBlur}
                           onKeyDown={event => event.preventDefault()}
                           error={touched.sourcePath && Boolean(errors.sourcePath)}
                           helperText={touched.sourcePath && errors.sourcePath}
                />
              }
            />
            {isLiveVersionSourcesFetching && <CircularProgress sx={{ml: 2}}/>}
          </fieldset>
        </>
      )}
    </AppForm>
  );
};