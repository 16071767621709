import { useUserAccessLevelSelect } from "./useUserAccessLevelSelect";
import { UpdateGroupUserDto } from "../../../models/domain/UpdateGroupUserDto";
import { MenuItem, Select } from "@mui/material";
import { UpdateGroupAccessLevelDto } from "../../../models/domain/enums";

export type UserAccessLevelSelectProps = {
  updateGroupUser: UpdateGroupUserDto;
}

export const UserAccessLevelSelect = (props: UserAccessLevelSelectProps) => {
  const {onChange} = useUserAccessLevelSelect(props.updateGroupUser);

  return (
    <Select
      value={props.updateGroupUser.accessLevel}
      onChange={onChange}
      sx={{minWidth: 200}}
      variant={"standard"}
    >
      <MenuItem value={UpdateGroupAccessLevelDto.NoAccess}>No Access</MenuItem>
      <MenuItem value={UpdateGroupAccessLevelDto.View}>View</MenuItem>
      <MenuItem value={UpdateGroupAccessLevelDto.Edit}>Edit</MenuItem>
    </Select>
  )
}