import { PageRoute, RootPageExtraNav, RootPageNav } from "../../routes";
import { NavLink } from "react-router-dom";
import React from "react";
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar
} from "@mui/material";

export type RootNavProps = {
  drawerWidth: number;
}

const NavItem = ({ label, path, icon }: PageRoute) => (
  <ListItem component={NavLink} to={path} disablePadding>
    <ListItemButton>
      <ListItemIcon>
        { icon }
      </ListItemIcon>
      <ListItemText primary={label} />
    </ListItemButton>
  </ListItem>
);

export const RootNav = ({ drawerWidth }: RootNavProps) => {
  const routes = Object.keys(RootPageNav);
  const extraRoutes = Object.keys(RootPageExtraNav);
  return (
    <Drawer variant="permanent" sx={{
      width: drawerWidth,
      flexShrink: 0,
      [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
    }}>
      <Toolbar/>
      <Box sx={{
          overflow: "auto",
          height: "100%",
          display: "flex",
          flexDirection: "column"
        }}>
        <List>
          {
            routes.map(route => (
              <NavItem key={route} {...RootPageNav[route]} />
            ))
          }
        </List>

        <Box sx={{ flexGrow: 1, alignSelf: "flex-end" }}></Box>
        <Divider/>

        <List>
          {
            extraRoutes.map(route => (
              <NavItem key={route} {...RootPageExtraNav[route]} />
            ))
          }
        </List>
      </Box>
    </Drawer>
  );
}