import { MrtBaseFieldProps } from "../mrt-base/mrt-base";
import { Typography } from "@mui/material";
import React from "react";

export type MrtYesNoFieldProps<T extends Record<string, any>> = MrtBaseFieldProps<T>

export const MrtYesNoField = <T extends Record<string, any>,>({cell, naValue} : MrtYesNoFieldProps<T>) => {
  const value = cell.getValue<boolean>();
  const yesNo = value ? "Yes" : "No";
  return (
    <Typography>{ yesNo || naValue || ''}</Typography>
  );
}