import React from 'react';
import { Button, CircularProgress, Divider, IconButton, Skeleton, Stack, Toolbar, Typography } from "@mui/material";
import { useVersionSourceProfile } from "./useVersionSourceProfile";
import { useParams } from "react-router-dom";
import { VersionSourceProfileForm } from "./VersionSourceProfileForm";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Delete } from "@mui/icons-material";
import { VersionSourceIcon } from "../../../comps/icons/VersionSourceIcon";

const VersionSourceItemPage = () => {
  const {versionSourceId} = useParams();
  const versionSourceProfile = useVersionSourceProfile(parseInt(versionSourceId || ''));
  const {data, isFetching, isLoading, refetch} = versionSourceProfile.primaryQuery;

  const onRefetch = React.useCallback(() => refetch(), [refetch]);

  return (
    <Stack>
      <Toolbar variant="dense">
        <Typography sx={{ mr: 1 }}>
          <VersionSourceIcon fontSize="large"/>
        </Typography>
        {
          isLoading
            ? <CircularProgress/>
            : <Typography component="h1" variant="h5" color="inherit">
                {data?.sourcePath}
              </Typography>
        }

        <Divider orientation="vertical" sx={{ ml: 2, mr: 2 }} flexItem />

        <Button variant="outlined" onClick={onRefetch} >
          <Typography>Refresh</Typography>
          { isFetching ? <CircularProgress size="20px" color="inherit" sx={{ml: 1}}/> : <RefreshIcon/>}
        </Button>

        <Button variant="outlined" color="warning" sx={{ml: 1}}
                disabled={isFetching || versionSourceProfile.isChangeAllowed}>
          <Typography>Delete</Typography>
          <Delete/>
        </Button>
      </Toolbar>

      <Divider sx={{ mt: 2, mb: 2 }} />

      {
        isLoading || !data
          ? <Skeleton/>
          : <>
            <VersionSourceProfileForm {...versionSourceProfile}/>
          </>
      }

    </Stack>
  );
}

export default VersionSourceItemPage;
