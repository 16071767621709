import Root from './routes/root/Root';
import { createBrowserRouter } from 'react-router-dom';
import { AdminAppRoutePaths } from "./routes";
import StartPage from "./routes/startPage/startPage";
import FarmListPage from "./routes/farms/list/farmListPage";
import FarmItemPage from "./routes/farms/item/farmItemPage";
import SettingsPage from "./routes/settingsPage/settingsPage";
import ProductVersionListPage from "./routes/productVersions/list/productVersionListPage";
import ProductVersionItemPage from "./routes/productVersions/item/productVersionItemPage";
import VersionSourceListPage from "./routes/versionSources/list/versionSourceListPage";
import VersionSourceItemPage from "./routes/versionSources/item/versionSourceItemPage";
import VersionSourceHostListPage from "./routes/versionSourceHosts/list/VersionSourceHostListPage";
import VersionSourceHostItemPage from "./routes/versionSourceHosts/item/VersionSourceHostItemPage";
import ProductVersionNewItemPage from "./routes/productVersions/newItem/ProductVersionNewItemPage";
import VersionSourceNewItemPage from "./routes/versionSources/newItem/VersionSourceNewItemPage";
import UpdateGroupListPage from "./routes/updateGroups/list/UpdateGroupListPage";
import UpdateGroupItemPage from "./routes/updateGroups/item/UpdateGroupItemPage";
import UpdateGroupNewItemPage from "./routes/updateGroups/newItem/UpdateGroupNewItemPage";
import SignIn from "./routes/signIn/SignIn";
import { AuthSection } from "./comps/auth/AuthSection";
import VersionSourceHostNewItemPage from "./routes/versionSourceHosts/newItem/VersionSourceHostNewItemPage";

export const router = createBrowserRouter([
  {
    path: AdminAppRoutePaths.rootPage(),
    element: <AuthSection><Root/></AuthSection>,
    children: [
      {
        // path: AdminAppRoutes.startPage,
        element: <StartPage />,
        index: true
      },
      {
        path: AdminAppRoutePaths.farms(),
        element: <FarmListPage />
      },

      {
        path: '/farms/:farmId',
        element: <FarmItemPage />
      },

      {
        path: AdminAppRoutePaths.productVersions(),
        element: <ProductVersionListPage />
      },
      {
        path: '/productVersions/:productVersionId',
        element: <ProductVersionItemPage />
      },
      {
        path: '/productVersions/new',
        element: <ProductVersionNewItemPage />
      },

      {
        path: AdminAppRoutePaths.updateGroups(),
        element: <UpdateGroupListPage />
      },
      {
        path: '/updateGroups/:updateGroupId',
        element: <UpdateGroupItemPage />
      },
      {
        path: '/updateGroups/new',
        element: <UpdateGroupNewItemPage />
      },

      {
        path: AdminAppRoutePaths.versionSources(),
        element: <VersionSourceListPage />
      },
      {
        path: '/versionSources/:versionSourceId',
        element: <VersionSourceItemPage />
      },
      {
        path: '/versionSources/new',
        element: <VersionSourceNewItemPage />
      },

      {
        path: AdminAppRoutePaths.versionSourceHosts(),
        element: <VersionSourceHostListPage />
      },
      {
        path: '/versionSourceHosts/:versionSourceHostId',
        element: <VersionSourceHostItemPage />
      },
      {
        path: '/versionSourceHosts/new',
        element: <VersionSourceHostNewItemPage />
      },

      {
        path: AdminAppRoutePaths.settings(),
        element: <SettingsPage />
      }
    ]
  },
  {
    path: AdminAppRoutePaths.signIn(),
    element: <AuthSection doRedirectToSignIn={false}><SignIn/></AuthSection>,
  }
])