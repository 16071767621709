import { SimpleComboBox, SimpleComboBoxProps } from "../simple-combo-box/SimpleComboBox";
import { UpdateStrategyDto } from "../../../models/domain/enums";
import { UpdateStrategyNames } from "../../../models/domain-enum-mapping";

export type UpdateStrategySelectProps = Omit<SimpleComboBoxProps<number>, "options">;

const updateStrategyOptions: SimpleComboBoxProps<number>["options"] = [
  { value: UpdateStrategyDto.LatestStable, label: UpdateStrategyNames[UpdateStrategyDto.LatestStable] },
  { value: UpdateStrategyDto.LatestBeta, label: UpdateStrategyNames[UpdateStrategyDto.LatestBeta] },
  { value: UpdateStrategyDto.Pinned, label: UpdateStrategyNames[UpdateStrategyDto.Pinned] },
];

export const UpdateStrategySelect = (props: UpdateStrategySelectProps) => (
  <SimpleComboBox options={updateStrategyOptions} {...props} />
)
