import { MrtBaseFieldProps } from "../mrt-base/mrt-base";
import { Typography } from "@mui/material";
import React from "react";
import {FileTargetBrandDto} from "../../../models/domain/enums";
import {FileTargetBrandNames} from "../../../models/domain-enum-mapping";

export type MrtFileTargetBrandFieldProps<T extends Record<string, any>> = MrtBaseFieldProps<T>

export const MrtFileTargetBrandField = <T extends Record<string, any>,>({cell, naValue} : MrtFileTargetBrandFieldProps<T>) => {
  const value = cell.getValue<FileTargetBrandDto>();
  const name = typeof value !== "undefined" && value in FileTargetBrandDto ? FileTargetBrandNames[value] : null;
  return (
    <Typography>{ name || naValue || ''}</Typography>
  );
}