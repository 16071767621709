import React from "react";
import { useMutation, UseMutationResult, useQuery, useQueryClient } from "@tanstack/react-query";
import Constants from "../constants";
import axios from "axios";
import { addDays, addMinutes, isBefore } from "date-fns";
import { ApiResourcePaths } from "../apiRoutes";
import * as userLocalStorage from "../core/localStorage";
import { User } from "../models/domain/User";
import { AuthHeader } from "../models/auth";
import { makeAuthHeader } from "../core/utils";

export type AuthSignInRequest = {
  username: string;
  password: string;
}

export type AuthSignInResponse = {
  userToken: string;
  refreshToken: string;
}

export type AuthReissueTokenResponse = {
  userToken: string;
  refreshToken: string;
}

export type AuthContextState = {
  isAuthChecking: boolean;
  isAuthenticated: boolean;
  token: string | null;
  refreshToken: string | null;
  user: User | null;
  authHeader: AuthHeader
  signInMutation: UseMutationResult<AuthSignInResponse | null, unknown, AuthSignInRequest>;
}

export const AuthContext = React.createContext<AuthContextState>(null as any);

export const useAuthContext = () => React.useContext(AuthContext);

export type AuthContextProviderProps = {
  children: React.ReactNode
}

const reissueTokenRequest = async (refreshToken: string): Promise<AuthReissueTokenResponse | null> => {
  const apiUrl = Constants.BACKEND_URL;
  const tokenResponse = await axios.put(ApiResourcePaths.user(apiUrl, "token"), { refreshToken });

  return tokenResponse && tokenResponse.status === 200 ? tokenResponse.data : null;
}

const signInRequest = async (request: AuthSignInRequest): Promise<AuthSignInResponse | null> => {
  const apiUrl = Constants.BACKEND_URL;
  const response = (await axios.post(ApiResourcePaths.user(apiUrl, "token"), request));

  return response.status === 200 ? response.data : null;
}

const getUserRequest = async (authHeader: AuthHeader): Promise<User | null> => {
  const apiUrl = Constants.BACKEND_URL;
  const response = await axios.get(ApiResourcePaths.user(apiUrl), {
    headers: authHeader
  });

  return response && response.status === 200 ? response.data : null;
}

export const AuthContextProvider = ({children}: AuthContextProviderProps) => {
  const queryClient = useQueryClient();
  const localStorageAuthState = userLocalStorage.getAuthState();
  const currentDate = new Date();

  const [token, setToken] = React.useState<string | null>(localStorageAuthState.token);
  const [tokenAliveUntil, setTokenAliveUntil] = React.useState<Date | null>(localStorageAuthState.tokenAliveUntil);
  const [refreshToken, setRefreshToken] = React.useState<string | null>(localStorageAuthState.refreshToken);
  const [refreshTokenAliveUntil, setRefreshTokenAliveUntil] = React.useState<Date | null>(localStorageAuthState.refreshTokenAliveUntil);
  const [authHeader, setAuthHeader] = React.useState<AuthHeader>(makeAuthHeader(localStorageAuthState.token));
  const [user, setUser] = React.useState<User | null>(localStorageAuthState.user);

  const userTokenValid = (!!token && tokenAliveUntil && isBefore(currentDate, tokenAliveUntil)) ?? false;
  const [isAuthChecking, setIsAuthChecking] = React.useState(!userTokenValid);
  const [isAuthenticated, setIsAuthenticated] = React.useState(userTokenValid);

  const clearAuth = React.useCallback(() => {
    setToken(null);
    setTokenAliveUntil(null);
    setRefreshToken(null);
    setRefreshTokenAliveUntil(null);
    setAuthHeader({ "Authorization": "" })
    setUser(null);
    setIsAuthChecking(true);
    setIsAuthenticated(false);

    userLocalStorage.clearAuthState();
  }, []);

  const signInMutation = useMutation({
    mutationFn: signInRequest,
    onSuccess: data => {
      if (!data) {
        clearAuth();
      }
      else {
        const now = new Date();
        setToken(data.userToken);
        setTokenAliveUntil(addMinutes(now, 10));
        setRefreshToken(data.refreshToken);
        setRefreshTokenAliveUntil(addDays(now, 3));
        setAuthHeader(makeAuthHeader(data.userToken))
        setIsAuthenticated(true);
        setIsAuthChecking(false);

        userLocalStorage.saveAuthStatePartial({
          token: data.userToken,
          tokenAliveUntil: addMinutes(now, 10),
          refreshToken: data.refreshToken,
          refreshTokenAliveUntil: addDays(now, 3)
        });
      }
    },
    onError: () => {
      clearAuth();
    }
  });

  const reissueToken = React.useCallback(() => {
    const now = new Date();
    return refreshToken && refreshTokenAliveUntil && isBefore(now, refreshTokenAliveUntil)
      ? reissueTokenRequest(refreshToken)
      : null;
  }, [refreshToken, refreshTokenAliveUntil]);

  useQuery<AuthReissueTokenResponse | null>(
    [ApiResourcePaths.user(null, "token"), "reissue"],
    reissueToken,
    {
      enabled: !!refreshToken,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: 10 * 60 * 1000, // 10 minutes
      refetchIntervalInBackground: true,
      onSuccess: data => {
        if (data && data.userToken && data.refreshToken) {
          const now = new Date();
          setToken(data.userToken);
          setTokenAliveUntil(addMinutes(now, 10));
          setRefreshToken(data.refreshToken);
          setRefreshTokenAliveUntil(addDays(now, 3));
          setAuthHeader(makeAuthHeader(data.userToken))

          userLocalStorage.saveAuthStatePartial({
            token: data.userToken,
            tokenAliveUntil: addMinutes(now, 10),
            refreshToken: data.refreshToken,
            refreshTokenAliveUntil: addDays(now, 3)
          });

          queryClient.invalidateQueries({
            queryKey: [ApiResourcePaths.user(null), "profile"],
            exact: true
          });
        }
        else
          clearAuth();
      },
      onError: () => {
        clearAuth();
      }
    });

  const getUser = React.useCallback(() => {
    return token && tokenAliveUntil && isBefore(new Date(), tokenAliveUntil) ? getUserRequest(authHeader) : null;
  }, [authHeader, token, tokenAliveUntil]);

  useQuery<User | null>(
    [ApiResourcePaths.user(null), "profile"],
    getUser,
    {
      enabled: !!token && isAuthenticated,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      initialData: localStorageAuthState.user,
      onSuccess: user => {
        if (!user) {
          setUser(null);
          userLocalStorage.saveAuthStatePartial({user: null});
        }
        else {
          setUser(user);
          userLocalStorage.saveAuthStatePartial({user});
        }
      },
      onError: () => {
        setUser(null);
        userLocalStorage.saveAuthStatePartial({user: null});
      }
    });

  const state: AuthContextState = {
    isAuthChecking,
    isAuthenticated,
    token,
    refreshToken,
    user,
    authHeader,
    signInMutation
  }

  return (
    <AuthContext.Provider value={state}>
      {children}
    </AuthContext.Provider>
  )
}
