import { regexTest, stringCompare, TableDataConfig } from "../../../core/table/TableDataConfig";
import { FarmDto } from "../../../models/domain/FarmDto";
import { ProductBrandDto } from "../../../models/domain/enums";

export const FarmConfig: TableDataConfig<FarmDto> = {
  farmId: {
    filterBy: value => obj => regexTest(value, obj.farmId),
    sortBy: (a, b) => stringCompare(a.farmId, b.farmId)
  },
  farmName: {
    filterBy: value => obj => regexTest(value, obj.farmName),
    sortBy: (a, b) => stringCompare(a.farmName, b.farmName)
  },
  teamviewerId: {
    filterBy: value => obj => regexTest(value, obj.teamviewerId),
    sortBy: (a, b) => stringCompare(a.teamviewerId, b.teamviewerId)
  },
  ecoHerdVersion: {
    filterBy: value => obj => regexTest(value, obj.ecoHerdVersion),
    sortBy: (a, b) => stringCompare(a.ecoHerdVersion, b.ecoHerdVersion)
  },
  ecoHerdPendingVersion: {
    filterBy: value => obj => regexTest(value, obj.ecoHerdPendingVersion),
    sortBy: (a, b) => stringCompare(a.ecoHerdPendingVersion, b.ecoHerdPendingVersion)
  },
  vendor: {
    filterBy: value => obj => regexTest(value, ProductBrandDto[obj.vendor]),
    sortBy: (a, b) => stringCompare(ProductBrandDto[a.vendor], ProductBrandDto[b.vendor])
  },
  dealer: {
    filterBy: value => obj => regexTest(value, obj.dealer),
    sortBy: (a, b) => stringCompare(a.dealer, b.dealer)
  },
  countryCode: {
    filterBy: value => obj => regexTest(value, obj.countryCode + " / " + obj.area),
    sortBy: (a, b) => stringCompare(a.countryCode + " / " + a.area, b.countryCode + " / " + b.area)
  },
  updateGroupId: {
    filterBy: value => obj => regexTest(value, obj.updateGroup?.name ?? obj.productVersion?.name ?? "No info"),
    sortBy: (a, b) => stringCompare(a.updateGroup?.name ?? a.productVersion?.name, b.updateGroup?.name ?? b.productVersion?.name)
  }
}