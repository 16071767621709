import React from 'react';
import { Divider, Stack, Toolbar, Typography } from "@mui/material";
import { useNewVersionSourceProfile } from "./useNewVersionSourceProfile";
import { NewVersionSourceProfileForm } from "./NewVersionSourceProfileForm";
import { ProductVersionIcon } from "../../../comps/icons/ProductVersionIcon";

const VersionSourceNewItemPage = () => {
  const versionSourceProfile = useNewVersionSourceProfile();

  return (
    <Stack>
      <Toolbar variant="dense">
        <Typography sx={{ mr: 1 }}>
          <ProductVersionIcon fontSize="large"/>
        </Typography>
        <Typography component="h1" variant="h5" color="inherit">
          New Version Source
        </Typography>

        <Divider orientation="vertical" sx={{ ml: 2, mr: 2 }} flexItem />
      </Toolbar>

      <Divider sx={{ mt: 2, mb: 2 }} />

      <NewVersionSourceProfileForm {...versionSourceProfile}/>
    </Stack>
  );
}

export default VersionSourceNewItemPage;
