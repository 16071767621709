import React from 'react';
import { Divider, Stack, Toolbar, Typography } from "@mui/material";
import { useNewUpdateGroupProfile } from "./useNewUpdateGroupProfile";
import { NewUpdateGroupProfileForm } from "./NewUpdateGroupProfileForm";
import { ProductVersionIcon } from "../../../comps/icons/ProductVersionIcon";

const UpdateGroupNewItemPage = () => {
  const updateGroupProfile = useNewUpdateGroupProfile();

  return (
    <Stack>
      <Toolbar variant="dense">
        <Typography sx={{ mr: 1 }}>
          <ProductVersionIcon fontSize="large"/>
        </Typography>
        <Typography component="h1" variant="h5" color="inherit">
          New Update Group
        </Typography>

        <Divider orientation="vertical" sx={{ ml: 2, mr: 2 }} flexItem />
      </Toolbar>

      <Divider sx={{ mt: 2, mb: 2 }} />

      <NewUpdateGroupProfileForm {...updateGroupProfile}/>
    </Stack>
  );
}

export default UpdateGroupNewItemPage;
