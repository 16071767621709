import {
  Autocomplete, CircularProgress,
  TextField,
} from "@mui/material";
import React from "react";
import { AppFormWithQuery, AppFormWithQueryProps } from "../../../core/form/AppForm";
import { ProductVersionDto } from "../../../models/domain/ProductVersionDto";
import { UpdateStrategySelect } from "../../../comps/fields/update-strategy-select/UpdateStrategySelect";
import { ProductVersionProfileExtraProps } from "./useProductVersionProfile";
import {
  ProductVersionStatusSelect
} from "../../../comps/fields/product-version-status-select/ProductVersionStatusSelect";

export const ProductVersionProfileForm = (props: AppFormWithQueryProps<ProductVersionDto, ProductVersionDto, ProductVersionProfileExtraProps>) => {
  const {primaryQuery: {data: initialValues}, isChangeAllowed} = props;
  const {versionSourcesQuery: {data: versionSources, isFetching: isVersionsSourcesFetching}} = props;

  return (
    <AppFormWithQuery {...props} initialValues={initialValues} formLabel="Product Version Profile">
      {({values, handleChange, handleBlur, touched, errors, setFieldValue}) => (
        <>
          <fieldset>
            <TextField
              id="name"
              name="name"
              label="Product Version Name"
              variant="standard"
              disabled={!isChangeAllowed}
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
            />
            <ProductVersionStatusSelect
              id="productVersionStatus"
              name="productVersionStatus"
              label="Version Status"
              variant="standard"
              disabled={!isChangeAllowed}
              value={values.productVersionStatus}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.productVersionStatus && Boolean(errors.productVersionStatus)}
              helperText={touched.productVersionStatus && errors.productVersionStatus}
            />
          </fieldset>
          <fieldset>
            <Autocomplete<number>
              id="versionSourceId"
              disabled={!isChangeAllowed}
              options={versionSources?.data?.items?.map(x => x.id) ?? []}
              getOptionLabel={option => {
                return versionSources?.itemsMap && versionSources?.itemsMap[option]
                  ? versionSources?.itemsMap[option].sourcePath
                  : "N/A";
              }}
              value={values.versionSource?.id}
              onChange={(_, value) => setFieldValue("versionSourceId", value)}
              onBlur={handleBlur}
              renderInput={(params) =>
                <TextField {...params}
                           variant="standard"
                           label="Linked Version Source"
                           onBlur={handleBlur}
                           onKeyDown={event => event.preventDefault()}
                           error={touched.versionSourceId && Boolean(errors.versionSourceId)}
                           helperText={touched.versionSourceId && errors.versionSourceId}
                />
              }

            />
            {isVersionsSourcesFetching && <CircularProgress sx={{ml: 2}}/>}
          </fieldset>
        </>
      )}
    </AppFormWithQuery>
  );
};