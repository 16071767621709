import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AdminAppRoutePaths } from "../../routes";
import { useAuthContext } from "../../hooks/useAuth";

export type AuthSectionProps = {
  children: React.ReactNode;
  doRedirectToSignIn?: boolean;
}

export const AuthSection = ({
  children,
  doRedirectToSignIn = true
}: AuthSectionProps) => {
  const { isAuthenticated } = useAuthContext();
  const navigate = useNavigate();
  const {pathname} = useLocation();

  React.useEffect(() => {
    if (isAuthenticated) {
      if (pathname === AdminAppRoutePaths.signIn())
        navigate(AdminAppRoutePaths.rootPage());
    }
    else {
      if (doRedirectToSignIn)
        navigate(AdminAppRoutePaths.signIn());
    }
  }, [doRedirectToSignIn, isAuthenticated, navigate, pathname]);

  return (
    <>
      {children}
    </>
  )
}