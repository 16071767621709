import { useMutation, useQuery, useQueryClient, UseQueryResult } from "@tanstack/react-query";
import { ApiResourcePaths } from "../../../apiRoutes";
import Constants from "../../../constants";
import axios from "axios";
import { useForm } from "../../../hooks/useForm";
import { PageInfoDto } from "../../../models/domain/pagination/PageInfoDto";
import { UpdateGroupDto } from "../../../models/domain/UpdateGroupDto";
import * as yup from "yup";
import { AppFormProps } from "../../../core/form/AppForm";
import { ProductVersionDto } from "../../../models/domain/ProductVersionDto";
import { useAuthContext } from "../../../hooks/useAuth";

export type ProductVersionsData = {
  data: PageInfoDto<ProductVersionDto>;
  itemsMap: Record<number, ProductVersionDto>;
}

export type FarmUpdateGroupExtraProps = {
  productVersionsQuery: UseQueryResult<ProductVersionsData>;
}

export type UpdateGroupProductVersionFormDto = {
  productVersionId: number | null;
}

const validationSchema = yup.object().shape({
  productVersionId: yup.number().nullable()
})

export const useUpdateGroupProductVersionProfile = (updateGroup: UpdateGroupDto | undefined): AppFormProps<UpdateGroupProductVersionFormDto, UpdateGroupDto, FarmUpdateGroupExtraProps> => {
  const formState = useForm();
  const queryClient = useQueryClient();
  const {authHeader} = useAuthContext();

  const productVersionsQuery = useQuery<ProductVersionsData>({
    queryKey: [
      ApiResourcePaths.productVersions(null),
    ],
    queryFn: async () => {
      const apiUrl = Constants.BACKEND_URL;
      const data = (await axios.get(ApiResourcePaths.productVersions(apiUrl), {headers:authHeader})).data;

      const itemsMap = data.items.reduce((acc: Record<number, ProductVersionDto>, cur: ProductVersionDto) => {
        return {...acc, [cur.id]: cur}
      }, {});

      return {data, itemsMap};
    }
  });

  const mutation = useMutation({
    mutationFn: async (formDto: UpdateGroupProductVersionFormDto): Promise<UpdateGroupDto> => {
      const apiUrl = Constants.BACKEND_URL;

      const productVersionId = formDto?.productVersionId;
      if (!productVersionId) {
        return (await axios.delete(ApiResourcePaths.updateGroupProductVersions(apiUrl, updateGroup?.id), {headers:authHeader})).data;
      } else {
        return (await axios.put(ApiResourcePaths.updateGroupProductVersions(apiUrl, updateGroup?.id, productVersionId), null,{headers:authHeader})).data;
      }
    },
    onMutate: _ => {
      formState.setIsControlBlocked(true);
    },
    onSuccess: _ => {
      queryClient.invalidateQueries({
        queryKey: [ApiResourcePaths.updateGroups()],
        exact: true
      });
      queryClient.invalidateQueries({
        queryKey: [ApiResourcePaths.updateGroups(null, updateGroup?.id)]
      });
      formState.setIsChangeAllowed(false);
    },
    onSettled: _ => {
      formState.setIsControlBlocked(false);
    }
  });

  return {
    ...formState,
    initialValues: {
      productVersionId: updateGroup?.effectiveProductVersionId ?? null
    },
    defaultValues: {
      productVersionId: null
    },
    validationSchema,
    productVersionsQuery,
    mutation,
  };
}