import React from 'react';
import { Divider, Stack, Toolbar, Typography } from "@mui/material";
import { useVersionSourceHostList } from "./useVersionSourceHostList";
import { ApiResourceTable } from "../../../comps/api-resource-table/ApiResourceTable";
import { LinkButton } from "../../../comps/link-button/LinkButton";
import { AdminAppRoutePaths } from "../../../routes";

const VersionSourceHostListPage = () => {
  const versionSourceHostList = useVersionSourceHostList();

  return (
    <Stack>
      <Toolbar>
        <Typography component="h1" variant="h5">
          Version Source Hosts
        </Typography>
        <Divider orientation="vertical" sx={{ ml: 1, mr: 1 }}/>
        <LinkButton variant="contained" to={AdminAppRoutePaths.versionSourceHostNew()}>
          <Typography>Add new</Typography>
        </LinkButton>
      </Toolbar>

      <Divider sx={{ mb: 2 }} />

      <ApiResourceTable {...versionSourceHostList} />
    </Stack>
  );
}

export default VersionSourceHostListPage;
