import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ApiResourcePaths } from "../../../apiRoutes";
import Constants from "../../../constants";
import axios from "axios";
import { useForm } from "../../../hooks/useForm";
import * as yup from "yup";
import { AppFormWithQueryProps } from "../../../core/form/AppForm";
import { VersionSourceHostDto } from "../../../models/domain/VersionSourceHostDto";
import { useAuthContext } from "../../../hooks/useAuth";

const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  connectionString: yup.string().required("Connection string is required"),
  containerName: yup.string().required("Container name is required"),
})

export const useVersionSourceHostProfile = (id: number): AppFormWithQueryProps<VersionSourceHostDto, VersionSourceHostDto> => {
  const formState = useForm();
  const queryClient = useQueryClient();
  const {authHeader} = useAuthContext();

  const primaryQuery = useQuery<VersionSourceHostDto>({
      queryKey: [
        ApiResourcePaths.versionSourceHosts(null, id),
      ],
      queryFn: async () => {
        const apiUrl = Constants.BACKEND_URL;
        return (await axios.get(ApiResourcePaths.versionSourceHosts(apiUrl, id), {
          headers: authHeader
        })).data;
      },
    });

  const mutation = useMutation({
    mutationFn: async (values: VersionSourceHostDto): Promise<VersionSourceHostDto> => {
      const apiUrl = Constants.BACKEND_URL;
      return (await axios.patch(ApiResourcePaths.versionSourceHosts(apiUrl, id), values, {
        headers: authHeader
      })).data;
    },
    onMutate: _ => {
      formState.setIsControlBlocked(true);
    },
    onSuccess: _ => {
      queryClient.invalidateQueries({
        queryKey: [ApiResourcePaths.versionSourceHosts()],
        exact: true
      });
      queryClient.invalidateQueries({
        queryKey: [ApiResourcePaths.versionSourceHosts(null, id)]
      });
      formState.setIsChangeAllowed(false);
    },
    onSettled: _ => {
      formState.setIsControlBlocked(false);
    }
  });

  return {
    ...formState,
    primaryQuery,
    mutation,
    validationSchema,
    defaultValues: {
      id: -1,
      name: "",
      connectionString: "",
      containerName: ""
    }
  };
}