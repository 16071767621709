import { User } from "../models/domain/User";
import { parseISO } from "date-fns";

const LOCAL_STORAGE_AUTH_STATE_KEY = 'AUTH_STATE';

export type AuthState = {
  token: string | null;
  tokenAliveUntil: Date | null;

  refreshToken: string | null;
  refreshTokenAliveUntil: Date | null;

  user: User | null;
}

export function saveAuthState(authState: AuthState): void {
  localStorage.setItem(LOCAL_STORAGE_AUTH_STATE_KEY, JSON.stringify(authState));
}

export function saveAuthStatePartial(partialAuthState: Partial<AuthState>): AuthState {
  const oldState = getAuthState();
  const newState = {
    ...oldState,
    ...partialAuthState
  };

  localStorage.setItem(LOCAL_STORAGE_AUTH_STATE_KEY, JSON.stringify(newState));

  return newState;
}

export function getAuthState(): AuthState {
  let state = localStorage.getItem(LOCAL_STORAGE_AUTH_STATE_KEY);
  if (!state) {
    saveAuthState({
      token: null,
      tokenAliveUntil: null,
      refreshToken: null,
      refreshTokenAliveUntil: null,
      user: null
    });

    state = localStorage.getItem(LOCAL_STORAGE_AUTH_STATE_KEY);
  }

  if (!state) return {} as AuthState;

  const parsedState = JSON.parse(state);
  parsedState.tokenAliveUntil = parsedState.tokenAliveUntil ? parseISO(parsedState.tokenAliveUntil) : null;
  parsedState.refreshTokenAliveUntil = parsedState.refreshTokenAliveUntil ? parseISO(parsedState.refreshTokenAliveUntil) : null;

  return parsedState;
}

export function clearAuthState(): AuthState {
  const newState = {
    token: null,
    tokenAliveUntil: null,
    refreshToken: null,
    refreshTokenAliveUntil: null,
    user: null
  };

  saveAuthState(newState);

  return newState;
}

// export function removeAuthState(): void {
//   localStorage.removeItem(LOCAL_STORAGE_AUTH_STATE_KEY);
// }