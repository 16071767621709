import { FarmDto } from "../../../models/domain/FarmDto";
import { FarmLinkDialogProps, useFarmLinkDialog } from "../farm-link-dialog/useFarmLinkDialog";

export type UseFarmLinkButtonState = {
  dialogProps: FarmLinkDialogProps;
}

export const useFarmLinkButton = (farm: FarmDto): UseFarmLinkButtonState => {
  const dialogProps = useFarmLinkDialog(farm);
  
  return {
    dialogProps
  }
}