import React from 'react';
import {
  Box,
  Button,
  TextField,
} from "@mui/material";
import { AppForm, AppFormProps } from "../../../core/form/AppForm";
import { UpdateGroupUserAccessExtraProps, UpdateGroupUserFormDto } from "./useUpdateGroupUserAccessProfile";
import { UpdateGroupUserDto } from "../../../models/domain/UpdateGroupUserDto";
import { ApiResourceTable } from "../../../comps/api-resource-table/ApiResourceTable";

export const UpdateGroupUserAccessForm = (props: AppFormProps<UpdateGroupUserFormDto, UpdateGroupUserDto, UpdateGroupUserAccessExtraProps>) => {
  const usersList = props.usersList;

  return (
    <AppForm {...props} formLabel="Update Group Access">
      {({values, handleChange, handleBlur, touched, errors, isChangeAllowed, submitForm}) => (
        <>
          <fieldset>
            <Box sx={{display: "flex", alignItems: "center"}}>
              <TextField
                id="userName"
                name="userName"
                label="User Name"
                variant="standard"
                disabled={!isChangeAllowed}
                value={values.userName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.userName && Boolean(errors.userName)}
                helperText={touched.userName && errors.userName}
              />
              <Button variant={"contained"} onClick={submitForm}>
                Add
              </Button>
            </Box>

          </fieldset>

          <fieldset>
            <Box sx={{mt: 1}}>
              <ApiResourceTable {...usersList} />
            </Box>
          </fieldset>
        </>
      )}
    </AppForm>
  );
}
