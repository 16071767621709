import { useMemo } from "react";
import { MaterialReactTableProps, MRT_ColumnDef } from "material-react-table";
import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { PageInfoDto } from "../models/domain/pagination/PageInfoDto";
import { MrtControlState, useMrt, UseMrtParams } from "./useMrt";
import { ApiResourceListParams } from "./useApiClient";
import {isFunction} from "../core/utils";

export type ApiResourceTableColumnFactory<T extends Record<string, any>> = () => MRT_ColumnDef<T>[];

export type UseApiResourceTableParams<T extends Record<string, any>, TQueryExtraParams extends {} = {}> = {
  columnFactory: ApiResourceTableColumnFactory<T>;
  queryKeyFn: () => string;
  requestFn: (params: ApiResourceListParams, extraParams?: TQueryExtraParams) => Promise<PageInfoDto<T>>;
  queryExtraParams?: (() => TQueryExtraParams) | (TQueryExtraParams);
  queryOptions?: Omit<UseQueryOptions<PageInfoDto<T>>, "queryKey" | "queryFn">;
  mrtParams?: UseMrtParams;
}

export type ApiResourceTableState<T extends Record<string, any>> =
  & MrtControlState
  & {
    overrideData?: PageInfoDto<T>;
    query: UseQueryResult<PageInfoDto<T>>;
    columns: MRT_ColumnDef<T>[];
    muiProps?: Pick<MaterialReactTableProps<T>, "muiTableBodyRowProps">;
    tableProps?: Pick<MaterialReactTableProps<T>,
      | "state"
      | "enableRowSelection"
      | "enableMultiRowSelection"
      | "enableMultiSort"
      | "onRowSelectionChange"
      | "getRowId"
      | "manualFiltering"
      | "manualPagination"
      | "manualSorting"
      | "maxMultiSortColCount"
      >
  };

export const useApiResourceTable = <T extends Record<string, any>, TQueryExtraParams extends {} = {}>(
  {
    columnFactory,
    queryKeyFn,
    requestFn,
    queryExtraParams,
    queryOptions,
    mrtParams
  }: UseApiResourceTableParams<T, TQueryExtraParams>): ApiResourceTableState<T> => {
  const mrtState = useMrt(mrtParams);

  const query = useQuery<PageInfoDto<T>>({
    queryKey: [
      queryKeyFn(),
      mrtState.columnFilters,
      mrtState.globalFilter,
      mrtState.pagination.pageIndex,
      mrtState.pagination.pageSize,
      mrtState.sorting,
    ],
    queryFn: async () => {
      const extraParams = isFunction(queryExtraParams) ? queryExtraParams() : queryExtraParams;
      const params: ApiResourceListParams = {
        page: mrtState.pagination.pageIndex,
        pageSize: mrtState.pagination.pageSize,
        filters: JSON.stringify(mrtState.columnFilters ?? []),
        globalFilter: mrtState.globalFilter ?? '',
        sorting: JSON.stringify(mrtState.sorting ?? []),
      };
      return extraParams == null ? requestFn(params) : requestFn(params, extraParams);
    },
    ...queryOptions
  });

  const columns = useMemo<MRT_ColumnDef<T>[]>(columnFactory, [columnFactory]);

  return {
    ...mrtState,
    query,
    columns
  };
}