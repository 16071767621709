import { MrtBaseFieldProps } from "../mrt-base/mrt-base";
import { Typography } from "@mui/material";
import React from "react";
import { UpdateStrategyDto } from "../../../models/domain/enums";
import { UpdateStrategyNames } from "../../../models/domain-enum-mapping";

export type MrtUpdateStrategyFieldProps<T extends Record<string, any>> = MrtBaseFieldProps<T>

export const MrtUpdateStrategyField = <T extends Record<string, any>,>({cell, naValue} : MrtUpdateStrategyFieldProps<T>) => {
  const value = cell.getValue<UpdateStrategyDto>();
  const name = !!value && value in UpdateStrategyDto ? UpdateStrategyNames[value] : null;
  return (
    <Typography>{ name || naValue || ''}</Typography>
  );
}