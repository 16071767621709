import { SimpleComboBox, SimpleComboBoxProps } from "../simple-combo-box/SimpleComboBox";
import { ProductVersionStatusDto } from "../../../models/domain/enums";
import { ProductVersionStatusNames } from "../../../models/domain-enum-mapping";

export type ProductVersionStatusSelectProps = Omit<SimpleComboBoxProps<number>, "options">;

const productVersionStatusOptions: SimpleComboBoxProps<number>["options"] = [
  { value: ProductVersionStatusDto.Unassigned, label: ProductVersionStatusNames[ProductVersionStatusDto.Unassigned] },
  { value: ProductVersionStatusDto.Beta, label: ProductVersionStatusNames[ProductVersionStatusDto.Beta] },
  { value: ProductVersionStatusDto.Stable, label: ProductVersionStatusNames[ProductVersionStatusDto.Stable] },
];

export const ProductVersionStatusSelect = (props: ProductVersionStatusSelectProps) => (
  <SimpleComboBox options={productVersionStatusOptions} {...props} />
)
