import {
  Autocomplete,
  CircularProgress,
  TextField,
} from "@mui/material";
import React from "react";
import { FarmUpdateGroupFormDto, FarmUpdateGroupExtraProps } from "./useFarmUpdateGroupProfile";
import { AppForm, AppFormProps } from "../../../core/form/AppForm";
import { UpdateGroupDto } from "../../../models/domain/UpdateGroupDto";
import { FarmDto } from "../../../models/domain/FarmDto";
import { getIn } from "formik";

export type FarmUpdateGroupFormProps = AppFormProps<FarmUpdateGroupFormDto, FarmDto, FarmUpdateGroupExtraProps>;

export const FarmUpdateGroupForm = (props: FarmUpdateGroupFormProps) => {
  const {data: groupsData, isFetching} = props.groupsQuery;
  const {isChangeAllowed} = props;

  return (
    <AppForm {...props} formLabel="Farm Update Group">
      {({values, touched, errors, handleBlur, setFieldValue}) => {
        return (
          <>
            <fieldset>
              <Autocomplete<UpdateGroupDto>
                id="updateGroup"
                disabled={!isChangeAllowed}
                options={groupsData?.items ?? []}
                getOptionLabel={option => option.name}
                value={values.updateGroup}
                onChange={(_, value) => setFieldValue("updateGroup", value)}
                onBlur={handleBlur}
                renderInput={(params) =>
                  <TextField {...params}
                             variant="standard"
                             label="Selected Update Group"
                             onBlur={handleBlur}
                             error={(getIn(touched, "updateGroup.id") && Boolean(getIn(errors, "updateGroup.id"))) || (touched.updateGroup && errors.updateGroup)}
                             helperText={(getIn(touched, "updateGroup.id") && getIn(errors, "updateGroup.id")) || (touched.updateGroup && getIn(errors, "updateGroup.id"))}
                  />
                }

              />
              {isFetching && <CircularProgress sx={{ml: 2}}/>}
            </fieldset>
          </>
        )
      }
      }
    </AppForm>
  );
};