import { ApiResourceTableColumnFactory } from "../../../hooks/useApiResourceTable";
import React from "react";
import {ProductVersionFileDto} from "../../../models/domain/ProductVersionFileDto";
import {
  MrtProductVersionFileKeyField
} from "../../../comps/mrt/mrt-product-version-file-key/MrtProductVersionFileKeyField";
import {MrtFileTargetBrandField} from "../../../comps/mrt/mrt-file-target-brand/MrtFileTargetBrandField";
import {MrtDownloadButtonField} from "../../../comps/mrt/mrt-download-button/MrtDownloadButtonField";
import {FileTargetBrandNames, ProductVersionFileKeyNames} from "../../../models/domain-enum-mapping";
import {FileTargetBrandDto, ProductVersionFileKeyDto} from "../../../models/domain/enums";

const productVersionFileKeySelectOptions = [
  { value: "Other", text: ProductVersionFileKeyNames[ProductVersionFileKeyDto.Other] },
  { value: "Updater", text: ProductVersionFileKeyNames[ProductVersionFileKeyDto.Updater] },
  { value: "Setup", text: ProductVersionFileKeyNames[ProductVersionFileKeyDto.Setup] },
  { value: "Commit", text: ProductVersionFileKeyNames[ProductVersionFileKeyDto.Commit] },
  { value: "Metadata", text: ProductVersionFileKeyNames[ProductVersionFileKeyDto.Metadata] },
  { value: "Manifest", text: ProductVersionFileKeyNames[ProductVersionFileKeyDto.Manifest] },
  { value: "ReleaseNotesByCommit", text: ProductVersionFileKeyNames[ProductVersionFileKeyDto.ReleaseNotesByCommit] },
  { value: "ReleaseNotes", text: ProductVersionFileKeyNames[ProductVersionFileKeyDto.ReleaseNotes] },
];

const fileTargetBrandSelectOptions = [
  { value: "Common", text: FileTargetBrandNames[FileTargetBrandDto.Common] },
  { value: "Abs", text: FileTargetBrandNames[FileTargetBrandDto.Abs] },
  { value: "Engs", text: FileTargetBrandNames[FileTargetBrandDto.Engs] },
  { value: "Ingenuity", text: FileTargetBrandNames[FileTargetBrandDto.Ingenuity] },
];

export const ProductVersionFilesColumnsFactory: ApiResourceTableColumnFactory<ProductVersionFileDto> = () => {
  return [
    {
      accessorKey: 'path',
      header: 'Name',
    },
    {
      accessorKey: "key",
      header: 'File Type',
      Cell: ({cell}) => <MrtProductVersionFileKeyField cell={cell}/>,
      filterVariant: "select",
      filterSelectOptions: productVersionFileKeySelectOptions,
    },
    {
      accessorKey: "targetBrand",
      header: 'Vendor',
      Cell: ({cell}) => <MrtFileTargetBrandField cell={cell}/>,
      filterVariant: "select",
      filterSelectOptions: fileTargetBrandSelectOptions,
    },
    {
      accessorKey: "downloadUrl",
      header: '',
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({cell}) => <MrtDownloadButtonField cell={cell} fileName={cell.row.original.path}/>
    },
  ];
}