import { ProductVersionStatusDto } from "../../models/domain/enums";
import { useProductVersionStatusButton } from "./useProductVersionStatusButton";
import { Button } from "@mui/material";
import React from "react";
import { ButtonProps } from "@mui/material/Button";

export type ProductVersionStatusButtonProps =
  ButtonProps &
  {
    productVersionId: number;
    targetStatus: ProductVersionStatusDto;
  };

export const ProductVersionStatusButton = (props: ProductVersionStatusButtonProps) => {
  const {productVersionId, targetStatus, children, ...restProps} = props;
  const buttonState = useProductVersionStatusButton(productVersionId, targetStatus);

  return (
    <Button variant={"outlined"} size={"small"} {...restProps} {...buttonState}>{children}</Button>
  );
}