import {
  TextField,
} from "@mui/material";
import React from "react";
import { AppForm, AppFormProps } from "../../../core/form/AppForm";
import { VersionSourceHostDto } from "../../../models/domain/VersionSourceHostDto";
import { NewVersionSourceHostDto } from "./useNewVersionSourceHostProfile";

export const NewVersionSourceHostProfileForm = (props: AppFormProps<NewVersionSourceHostDto, VersionSourceHostDto>) => {
  const {isChangeAllowed} = props;

  return (
    <AppForm {...props} formLabel="New Version Source Host Profile" controls={{showEdit: false, showCancel: false}}>
      {({values, handleChange, handleBlur, touched, errors}) => (
        <>
          <fieldset>
            <TextField
              id="name"
              name="name"
              label="Host Name"
              variant="standard"
              disabled={!isChangeAllowed}
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
            />
          </fieldset>
          <fieldset>
            <TextField
              id="connectionString"
              name="connectionString"
              label="Connection String"
              variant="standard"
              disabled={!isChangeAllowed}
              value={values.connectionString}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.connectionString && Boolean(errors.connectionString)}
              helperText={touched.connectionString && errors.connectionString}
            />
            <TextField
              id="containerName"
              name="containerName"
              label="Container Name"
              variant="standard"
              disabled={!isChangeAllowed}
              value={values.containerName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.containerName && Boolean(errors.containerName)}
              helperText={touched.containerName && errors.containerName}
            />
          </fieldset>
        </>
      )}
    </AppForm>
  );
};