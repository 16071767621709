import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Divider, FormControlLabel,
  FormGroup, IconButton,
  Skeleton,
  Stack, Switch,
  TextField,
  Toolbar, Tooltip,
  Typography
} from "@mui/material";
import { useUpdateGroupProfile } from "./useUpdateGroupProfile";
import { useParams } from "react-router-dom";
import { UpdateGroupProfileForm } from "./UpdateGroupProfileForm";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Delete } from "@mui/icons-material";
import { UpdateGroupIcon } from "../../../comps/icons/UpdateGroupIcon";
import { useUpdateGroupProductVersionProfile } from "./useUpdateGroupProductVersionProfile";
import { UpdateGroupProductVersionForm } from "./UpdateGroupProductVersionForm";
import { UpdateStrategyDto } from "../../../models/domain/enums";
import { useUpdateGroupUserAccessProfile } from "./useUpdateGroupUserAccessProfile";
import { UpdateGroupUserAccessForm } from "./UpdateGroupUserAccessForm";
import { UpdateGroupFarmsForm } from "./UpdateGroupFarmsForm";
import { useUpdateGroupFarmsProfile } from "./useUpdateGroupFarmsProfile";

const UpdateGroupItemPage = () => {
  const {updateGroupId} = useParams();
  const updateGroupProfile = useUpdateGroupProfile(parseInt(updateGroupId || ''));
  const {data, isFetching, isLoading, refetch} = updateGroupProfile.primaryQuery;

  const updateGroupProductVersionProfile = useUpdateGroupProductVersionProfile(data);
  const updateGroupFarmsProfile = useUpdateGroupFarmsProfile(data);
  const updateGroupUserAccessProfile = useUpdateGroupUserAccessProfile(data);

  const onRefetch = React.useCallback(() => refetch(), [refetch]);

  return (
    <Stack>
      <Toolbar variant="dense">
        <Typography sx={{ mr: 1 }}>
          <UpdateGroupIcon fontSize="large"/>
        </Typography>
        {
          isLoading
            ? <CircularProgress/>
            : <Typography component="h1" variant="h5" color="inherit">
                {data?.name}
              </Typography>
        }

        <Divider orientation="vertical" sx={{ ml: 2, mr: 2 }} flexItem />

        <Button variant="outlined" onClick={onRefetch} >
          <Typography>Refresh</Typography>
          { isFetching ? <CircularProgress size="20px" color="inherit" sx={{ml: 1}}/> : <RefreshIcon/>}
        </Button>

        <Button variant="outlined" color="warning" sx={{ml: 1}}
                disabled={isFetching || updateGroupProfile.isChangeAllowed}>
          <Typography>Delete</Typography>
          <Delete/>
        </Button>
      </Toolbar>

      <Divider sx={{ mt: 2, mb: 2 }} />

      {
        isLoading || !data
          ? <Skeleton/>
          : <>
            <UpdateGroupProfileForm {...updateGroupProfile}/>
            {data && data.updateStrategy === UpdateStrategyDto.Pinned && <UpdateGroupProductVersionForm {...updateGroupProductVersionProfile} sx={{mt: 2}}/>}

            <UpdateGroupFarmsForm {...updateGroupFarmsProfile} />

            <UpdateGroupUserAccessForm {...updateGroupUserAccessProfile} sx={{mt: 2}} />
          </>
      }

    </Stack>
  );
}

export default UpdateGroupItemPage;
