import { ApiResourceTableColumnFactory } from "../../../hooks/useApiResourceTable";
import React from "react";
import {ProductVersionFileDto} from "../../../models/domain/ProductVersionFileDto";
import {
  MrtProductVersionFileKeyField
} from "../../../comps/mrt/mrt-product-version-file-key/MrtProductVersionFileKeyField";
import {MrtFileTargetBrandField} from "../../../comps/mrt/mrt-file-target-brand/MrtFileTargetBrandField";
import {MrtDownloadButtonField} from "../../../comps/mrt/mrt-download-button/MrtDownloadButtonField";
import {FileTargetBrandNames, ProductVersionFileKeyNames} from "../../../models/domain-enum-mapping";
import {FileTargetBrandDto, ProductVersionFileKeyDto} from "../../../models/domain/enums";
import {UpdateGroupFarmDto} from "../../../models/domain/UpdateGroupFarmDto";
import {FarmLinkStatus} from "../../../comps/farm/farm-link-status/FarmLinkStatus";
import {FarmLinkButton} from "../../../comps/farm/farm-link-button/FarmLinkButton";
import {
  UpdateGroupFarmLinkButton
} from "../../../comps/update-group/update-group-farm-link-button/UpdateGroupFarmLinkButton";
import {UpdateGroupDto} from "../../../models/domain/UpdateGroupDto";

const productVersionFileKeySelectOptions = [
  { value: "Other", text: ProductVersionFileKeyNames[ProductVersionFileKeyDto.Other] },
  { value: "Updater", text: ProductVersionFileKeyNames[ProductVersionFileKeyDto.Updater] },
  { value: "Setup", text: ProductVersionFileKeyNames[ProductVersionFileKeyDto.Setup] },
  { value: "Commit", text: ProductVersionFileKeyNames[ProductVersionFileKeyDto.Commit] },
  { value: "Metadata", text: ProductVersionFileKeyNames[ProductVersionFileKeyDto.Metadata] },
  { value: "Manifest", text: ProductVersionFileKeyNames[ProductVersionFileKeyDto.Manifest] },
  { value: "ReleaseNotesByCommit", text: ProductVersionFileKeyNames[ProductVersionFileKeyDto.ReleaseNotesByCommit] },
  { value: "ReleaseNotes", text: ProductVersionFileKeyNames[ProductVersionFileKeyDto.ReleaseNotes] },
];

const fileTargetBrandSelectOptions = [
  { value: "Common", text: FileTargetBrandNames[FileTargetBrandDto.Common] },
  { value: "Abs", text: FileTargetBrandNames[FileTargetBrandDto.Abs] },
  { value: "Engs", text: FileTargetBrandNames[FileTargetBrandDto.Engs] },
  { value: "Ingenuity", text: FileTargetBrandNames[FileTargetBrandDto.Ingenuity] },
];

export const UpdateGroupFarmsColumnsFactory = (updateGroup: UpdateGroupDto | undefined): ApiResourceTableColumnFactory<UpdateGroupFarmDto> =>
  (() => {
  return [
    {
      accessorKey: 'farmId',
      header: 'Farm ID',
    },
    {
      accessorKey: "farmName",
      header: 'Farm Name',
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      accessorKey: 'updateGroupId',
      header: 'Update group | Product Version',
      Cell: ({row}) => <>
        <FarmLinkStatus farm={row.original}/>
      </>,
    },
    {
      accessorKey: 'updateGroupId',
      header: '',
      Cell: ({row}) => <>
        <UpdateGroupFarmLinkButton farm={row.original} updateGroup={updateGroup}/>
      </>,
      enableColumnFilter: false,
      enableSorting: false,
    },
  ];
})