import {
  TextField,
} from "@mui/material";
import React from "react";
import { AppForm, AppFormProps } from "../../../core/form/AppForm";
import {
  NewUpdateGroupDto,
} from "./useNewUpdateGroupProfile";
import { UpdateGroupDto } from "../../../models/domain/UpdateGroupDto";
import { UpdateStrategySelect } from "../../../comps/fields/update-strategy-select/UpdateStrategySelect";

export const NewUpdateGroupProfileForm = (props: AppFormProps<NewUpdateGroupDto, UpdateGroupDto>) => {
  return (
    <AppForm {...props} formLabel="Update Group Profile" controls={{showEdit: false, showCancel: false}}>
      {({values, handleChange, handleBlur, touched, errors, isChangeAllowed}) => (
        <>
          <fieldset>
            <TextField
              id="name"
              name="name"
              label="Update Group Name"
              variant="standard"
              disabled={!isChangeAllowed}
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
            />
            <UpdateStrategySelect
              id="updateStrategy"
              name="updateStrategy"
              label="Update Strategy"
              variant="standard"
              disabled={!isChangeAllowed}
              value={values.updateStrategy}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.updateStrategy && Boolean(errors.updateStrategy)}
              helperText={touched.updateStrategy && errors.updateStrategy}
            />
          </fieldset>
        </>
      )}
    </AppForm>
  );
};