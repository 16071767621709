import { FarmDto } from "../../../models/domain/FarmDto";
import { Button } from "@mui/material";
import { useFarmLinkButton } from "./useFarmLinkButton";
import { FarmLinkDialog } from "../farm-link-dialog/FarmLinkDialog";

export type FarmLinkButtonProps = {
  farm: FarmDto
}

export const FarmLinkButton = ({farm}: FarmLinkButtonProps) => {
  const {dialogProps} = useFarmLinkButton(farm);
  const {handleOpen} = dialogProps;

  return (
    <>
      <Button variant={"contained"} size={"small"} onClick={handleOpen}>Change</Button>
      <FarmLinkDialog {...dialogProps}/>
    </>
  )
}