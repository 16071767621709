import {
  FormControl, FormControlProps, FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  TextFieldProps,
} from "@mui/material";

export type SimpleComboBoxProps<T extends (string | number)> = Omit<SelectProps<T>, "id"> &
Pick<FormControlProps, "fullWidth"> &
{
  id: string;
  options: Array<{ value: T, label: string }>;
  helperText?: TextFieldProps["helperText"]
}

export const SimpleComboBox = <T extends (string | number),>(props: SimpleComboBoxProps<T>) => {
  const { id, fullWidth, label, value, options, helperText, error, ...restSelectProps } = props;

  return (
    <FormControl fullWidth={fullWidth} error={error}>
      <InputLabel id={`${id}-label`}
                  disabled={props.disabled}
                  sx={{
                    transform: "translate(0px, -9px) scale(0.75)"
                  }}>
        {label}
      </InputLabel>
      <Select
        id={id}
        labelId={`${id}-label`}
        value={value}
        label={label}
        {...restSelectProps}
      >
        {
          options.map(({value: optionValue, label: optionLabel}) => (
            <MenuItem key={optionValue} value={optionValue}>{optionLabel}</MenuItem>
          ))
        }
      </Select>
      {!!helperText && <FormHelperText sx={{ ml: 0 }}>{helperText}</FormHelperText> }
    </FormControl>
  )
}