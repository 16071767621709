import { useState } from "react";
import { MRT_ColumnFiltersState, MRT_PaginationState, MRT_SortingState } from "material-react-table";
import { StateChangeFn } from "../core/utils";

export type UseMrtParams = {
  initialColumnFilters?: MRT_ColumnFiltersState;
  initialGlobalFilter?: string;
  initialSorting?: MRT_SortingState;
  initialPagination?: MRT_PaginationState;
}

export type MrtControlState = {
  columnFilters: MRT_ColumnFiltersState;
  setColumnFilters: StateChangeFn<MRT_ColumnFiltersState>;

  globalFilter: string;
  setGlobalFilter: StateChangeFn<string>;

  sorting: MRT_SortingState;
  setSorting: StateChangeFn<MRT_SortingState>;

  pagination: MRT_PaginationState;
  setPagination: StateChangeFn<MRT_PaginationState>;
}

export const useMrt = (params?: UseMrtParams): MrtControlState => {
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>((params && params.initialColumnFilters) ?? []);
  const [globalFilter, setGlobalFilter] = useState((params && params.initialGlobalFilter) ?? '');
  const [sorting, setSorting] = useState<MRT_SortingState>((params && params.initialSorting) ?? []);
  const [pagination, setPagination] = useState<MRT_PaginationState>((params && params.initialPagination) ?? {
    pageIndex: 0,
    pageSize: 10,
  });

  return {
    columnFilters,
    setColumnFilters,
    globalFilter,
    setGlobalFilter,
    sorting,
    setSorting,
    pagination,
    setPagination,
  };
}