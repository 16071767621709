import { useMutation, useQuery, useQueryClient, UseQueryResult } from "@tanstack/react-query";
import { ApiResourcePaths } from "../../../apiRoutes";
import Constants from "../../../constants";
import axios from "axios";
import { useForm } from "../../../hooks/useForm";
import * as yup from "yup";
import { enumType } from "../../../core/validation/helpers";
import { ProductVersionStatusDto } from "../../../models/domain/enums";
import { AppFormProps } from "../../../core/form/AppForm";
import { ProductVersionDto } from "../../../models/domain/ProductVersionDto";
import { PageInfoDto } from "../../../models/domain/pagination/PageInfoDto";
import { VersionSourceDto } from "../../../models/domain/VersionSourceDto";
import { useNavigate } from "react-router-dom";
import React from "react";
import { AdminAppRoutePaths } from "../../../routes";
import { useAuthContext } from "../../../hooks/useAuth";

export type VersionSourcesData = {
  data: PageInfoDto<VersionSourceDto>;
  itemsMap: Record<string, VersionSourceDto>;
}

export type NewProductVersionExtraProps = {
  versionSourcesQuery: UseQueryResult<VersionSourcesData>;
}

export type NewProductVersionDto = Omit<ProductVersionDto, "id">;

const validationSchema = yup.object().shape({
  name: yup.string().required("Product version name is required"),
  versionSourceId: yup.number().moreThan(0, "Version source is required").required("Version source is required"),
  versionChannel: enumType(ProductVersionStatusDto).required("Status is required"),
})

export const useNewProductVersionProfile = (): AppFormProps<NewProductVersionDto, ProductVersionDto, NewProductVersionExtraProps> => {
  const formState = useForm(true);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {authHeader} = useAuthContext();

  const versionSourcesQuery = useQuery<VersionSourcesData>({
    queryKey: [
      ApiResourcePaths.versionSources(null),
    ],
    queryFn: async () => {
      const apiUrl = Constants.BACKEND_URL;
      const data = (await axios.get(ApiResourcePaths.versionSources(apiUrl), {headers:authHeader})).data as PageInfoDto<VersionSourceDto>;

      const itemsMap = data.items.reduce((acc: Record<number, VersionSourceDto>, cur: VersionSourceDto) => {
        return {...acc, [cur.id]: cur}
      }, {});

      return {data, itemsMap};
    }
  });

  const mutation = useMutation({
    mutationFn: async (values: NewProductVersionDto): Promise<ProductVersionDto> => {
      const apiUrl = Constants.BACKEND_URL;
      return (await axios.post(ApiResourcePaths.productVersions(apiUrl), values, {headers:authHeader})).data;
    },
    onMutate: _ => {
      formState.setIsControlBlocked(true);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [ApiResourcePaths.productVersions()],
        exact: true
      });
      queryClient.invalidateQueries({
        queryKey: [ApiResourcePaths.productVersions(null, data?.id)]
      });
      formState.setIsChangeAllowed(false);
    },
    onSettled: _ => {
      formState.setIsControlBlocked(false);
    }
  });

  const onSuccess = React.useCallback((_: any, data: ProductVersionDto) => {
    navigate(AdminAppRoutePaths.productVersionId(data.id));
  }, [navigate]);

  return {
    ...formState,
    versionSourcesQuery,
    mutation,
    validationSchema,
    onSuccess,
    defaultValues: {
      name: "",
      versionSourceId: -1,
      pipelineBuildTimestamp: null,
      metadata: null,
      versionSource: {
        id: -1,
        versionSourceHostId: -1,
        sourcePath: "",
        versionSourceHost: {
          id: -1,
            name: "",
            connectionString: "",
            containerName: ""
        }
      },
      productVersionStatus: ProductVersionStatusDto.Unassigned
    }
  };
}