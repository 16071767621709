import React from "react";
import { FarmIcon } from "./comps/icons/FarmIcon";
import { DashboardIcon } from "./comps/icons/DashboardIcon";
import { ProductVersionIcon } from "./comps/icons/ProductVersionIcon";
import { UpdateGroupIcon } from "./comps/icons/UpdateGroupIcon";
import { SettingsIcon } from "./comps/icons/SettingsIcon";

export interface PageRoute {
  label: string;
  path: string;
  icon: React.ReactNode;
}

const RouteFn = (routeName: string, id: number | string) => `/${routeName}/${id}`;

export const AdminAppRoutePaths = {
  rootPage: () => "/",

  signIn: () => "/login",

  farms: () => "/farms",
  farmId: (id: string) => RouteFn("farms", id),
  farmNew: () => "/farms/new",

  productVersions: () => "/productVersions",
  productVersionId: (id: number) => RouteFn("productVersions", id),
  productVersionNew: () => "/productVersions/new",

  updateGroups: () => "/updateGroups",
  updateGroupId: (id: number) => RouteFn("updateGroups", id),
  updateGroupNew: () => "/updateGroups/new",

  versionSources: () => "/versionSources",
  versionSourceId: (id: number) => RouteFn("versionSources", id),
  versionSourceNew: () => "/versionSources/new",

  versionSourceHosts: () => "/versionSourceHosts",
  versionSourceHostId: (id: number) => RouteFn("versionSourceHosts", id),
  versionSourceHostNew: () => "/versionSourceHosts/new",

  settings: () => "/settings",

  userAccess: () => "/settings/userAccess",
  auditLog: () => "/settings/auditLog",

  teamviewerIntegration: (teamviewerId: string) => `teamviewer8://remotecontrol?connectcc=${teamviewerId}`,
}

export const RootPageNav: Record<string, PageRoute> = {
  "start": {
    "label": "Home",
    "icon": <DashboardIcon/>,
    "path": AdminAppRoutePaths.rootPage(),
  },
  "farms": {
    "label": "Farms",
    "icon": <FarmIcon/>,
    "path": AdminAppRoutePaths.farms()
  },
  "productVersions": {
    "label": "Product versions",
    "icon": <ProductVersionIcon/>,
    "path": AdminAppRoutePaths.productVersions()
  },
  "updateGroups": {
    "label": "Update Groups",
    "icon": <UpdateGroupIcon/>,
    "path": AdminAppRoutePaths.updateGroups()
  }
}

export const RootPageExtraNav: Record<string, PageRoute> = {
  "settings": {
    "label": "Settings",
    "icon": <SettingsIcon/>,
    "path": AdminAppRoutePaths.settings()
  }
}