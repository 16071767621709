import React, { useMemo, useState, useEffect } from "react";
import { MRT_ColumnDef, MRT_ColumnFiltersState, MRT_PaginationState, MRT_SortingState } from "material-react-table";
import { useQuery } from "@tanstack/react-query";
import { PageInfoDto } from "../../../models/domain/pagination/PageInfoDto";
import { FarmDto } from "../../../models/domain/FarmDto";
import { ApiResourcePaths } from "../../../apiRoutes";
import Constants from "../../../constants";
import axios from "axios";
import { MrtBrandField } from "../../../comps/mrt/mrt-brand/MrtBrandField";
import { LinkButton } from "../../../comps/link-button/LinkButton";
import { AdminAppRoutePaths } from "../../../routes";
import { ApiResourceTableProps } from "../../../comps/api-resource-table/ApiResourceTable";
import { useAuthContext } from "../../../hooks/useAuth";
import { FarmLinkStatus } from "../../../comps/farm/farm-link-status/FarmLinkStatus";
import { FarmLinkButton } from "../../../comps/farm/farm-link-button/FarmLinkButton";
import { CopyTextButton } from "../../../comps/copy-text-button/CopyTextButton";
import { getShapedData } from "../../../core/table/GetShapedData";
import { useDataShaper } from "../../../core/table/useDataShaper";
import { FarmConfig } from "./FarmConfig";

export const useFullFarmList = (): ApiResourceTableProps<FarmDto> => {
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const shaper = useDataShaper(FarmConfig, { globalFilter, columnFilters, sorting, ...pagination })

  const [overrideData, setOverrideData] = useState<PageInfoDto<FarmDto>>();

  const {authHeader} = useAuthContext();

  const query = useQuery<PageInfoDto<FarmDto>>({
      queryKey: [ApiResourcePaths.farms(),null,null,null,null,null],
      queryFn: async () => {
        const apiUrl = Constants.BACKEND_URL;

        return (await axios.get(ApiResourcePaths.allFarms(apiUrl), {headers: authHeader})).data;
      },
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      retry: false,
      staleTime: 24 * 60 * 60 * 1000,
    });

  const { data } = query;

  useEffect(
    () => setOverrideData(getShapedData(data, shaper)),
    [data, setOverrideData, shaper]);

  const columns = useMemo<MRT_ColumnDef<FarmDto>[]>(
    () => {
      return [
        {
          accessorKey: 'farmId',
          header: 'Farm ID',
          Cell: ({renderedCellValue, row}) => <LinkButton
            to={AdminAppRoutePaths.farmId(row.original.farmId)}>{renderedCellValue}</LinkButton>
        },
        {
          accessorKey: 'farmName',
          header: 'Farm Name',
          Cell: ({renderedCellValue, row}) => <LinkButton
            to={AdminAppRoutePaths.farmId(row.original.farmId)}>{renderedCellValue}</LinkButton>,
        },
        {
          accessorKey: 'teamviewerId',
          header: 'Teamviewer ID',
          Cell: ({row}) =>
            !!row.original.teamviewerId && <CopyTextButton text={row.original.teamviewerId}/>,
        },
        {
          accessorKey: 'ecoHerdVersion',
          header: 'EcoHerd Version',
        },
        {
          accessorKey: 'ecoHerdPendingVersion',
          header: 'Pending Version',
        },
        {
          accessorKey: 'vendor',
          header: 'Vendor',
          Cell: ({cell}) => <MrtBrandField cell={cell}/>,
        },
        {
          accessorKey: 'dealer',
          header: 'Dealer',
        },
        {
          accessorKey: 'countryCode',
          header: 'Country / Address',
          Cell: ({row}) => row.original.countryCode + " / " + row.original.area,
        },
        {
          accessorKey: 'updateGroupId',
          header: 'Update group | Product Version',
          Cell: ({row}) => <>
            <FarmLinkStatus farm={row.original}/>
            <FarmLinkButton farm={row.original}/>
          </>,
        },
      ];
    },
    [],
  );

  return {
    columnFilters,
    setColumnFilters,
    globalFilter,
    setGlobalFilter,
    sorting,
    setSorting,
    pagination,
    setPagination,
    overrideData,
    query,
    columns,
    tableProps: {
      enableMultiSort: true,
      maxMultiSortColCount: 3,
      manualFiltering: true,
      manualSorting: true,
      manualPagination: true,
    }
  };
}