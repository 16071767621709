import { useMutation, useQuery, useQueryClient, UseQueryResult } from "@tanstack/react-query";
import { ApiResourcePaths } from "../../../apiRoutes";
import Constants from "../../../constants";
import axios from "axios";
import { useForm } from "../../../hooks/useForm";
import * as yup from "yup";
import { AppFormWithQueryProps } from "../../../core/form/AppForm";
import { PageInfoDto } from "../../../models/domain/pagination/PageInfoDto";
import { VersionSourceDto } from "../../../models/domain/VersionSourceDto";
import { VersionSourceHostDto } from "../../../models/domain/VersionSourceHostDto";
import { LiveVersionSourceDto } from "../../../models/domain/LiveVersionSourceDto";
import { useState } from "react";
import { useAuthContext } from "../../../hooks/useAuth";

export type VersionSourceHostsData = {
  data: PageInfoDto<VersionSourceHostDto>;
  itemsMap: Record<number, VersionSourceHostDto>;
}

export type LiveVersionSourcesData = {
  data: PageInfoDto<LiveVersionSourceDto>;
  itemsMap: Record<string, LiveVersionSourceDto>;
}

export type VersionSourceProfileExtraProps = {
  versionSourceHostsQuery: UseQueryResult<VersionSourceHostsData>;
  liveVersionSourcesQuery: UseQueryResult<LiveVersionSourcesData>;
  setSelectedHostId: (value: number | null) => void;
}

const validationSchema = yup.object().shape({
  sourcePath: yup.string().required("Source path is required"),
  versionSourceHostId: yup.number().moreThan(0, "Version source host is required").required("Version source host is required"),
})

export const useVersionSourceProfile = (id: number): AppFormWithQueryProps<VersionSourceDto, VersionSourceDto, VersionSourceProfileExtraProps> => {
  const formState = useForm();
  const queryClient = useQueryClient();
  const [selectedHostId, setSelectedHostId] = useState(null as number | null);

  const {authHeader} = useAuthContext();

  const primaryQuery = useQuery<VersionSourceDto>({
      queryKey: [
        ApiResourcePaths.versionSources(null, id),
      ],
      queryFn: async () => {
        const apiUrl = Constants.BACKEND_URL;
        return (await axios.get(ApiResourcePaths.versionSources(apiUrl, id), {headers:authHeader})).data;
      },
    });

  const versionSourceHostsQuery = useQuery<VersionSourceHostsData>({
    queryKey: [
      ApiResourcePaths.versionSourceHosts(null),
    ],
    queryFn: async () => {
      const apiUrl = Constants.BACKEND_URL;
      const data = (await axios.get(ApiResourcePaths.versionSourceHosts(apiUrl), {headers:authHeader})).data as PageInfoDto<VersionSourceHostDto>;

      const itemsMap = data.items.reduce((acc: Record<number, VersionSourceHostDto>, cur: VersionSourceHostDto) => {
        return {...acc, [cur.id]: cur}
      }, {});

      return {data, itemsMap};
    }
  });

  const liveVersionSourcesQuery = useQuery<LiveVersionSourcesData>({
    queryKey: [
      ApiResourcePaths.liveVersionSources(null, selectedHostId)
    ],
    queryFn: async (): Promise<LiveVersionSourcesData> => {
      const apiUrl = Constants.BACKEND_URL;
      const data = (await axios.get(ApiResourcePaths.liveVersionSources(apiUrl, selectedHostId), {headers:authHeader})).data as PageInfoDto<LiveVersionSourceDto>;

      const itemsMap = data.items.reduce((acc: Record<string, LiveVersionSourceDto>, cur: LiveVersionSourceDto) => {
        return {...acc, [cur.path]: cur};
      }, {});

      return {
        data,
        itemsMap
      }
    },
    enabled: !!selectedHostId
  });

  const mutation = useMutation({
    mutationFn: async (values: VersionSourceDto): Promise<VersionSourceDto> => {
      const apiUrl = Constants.BACKEND_URL;
      return (await axios.patch(ApiResourcePaths.versionSources(apiUrl, id), values, {headers:authHeader})).data;
    },
    onMutate: _ => {
      formState.setIsControlBlocked(true);
    },
    onSuccess: _ => {
      queryClient.invalidateQueries({
        queryKey: [ApiResourcePaths.versionSources()],
        exact: true
      });
      queryClient.invalidateQueries({
        queryKey: [ApiResourcePaths.versionSources(null, id)]
      });

      if (selectedHostId) {
        queryClient.invalidateQueries({
          queryKey: [ApiResourcePaths.liveVersionSources(null, selectedHostId)]
        });
      }

      formState.setIsChangeAllowed(false);
    },
    onSettled: _ => {
      formState.setIsControlBlocked(false);
    }
  });

  return {
    ...formState,
    primaryQuery,
    versionSourceHostsQuery,
    liveVersionSourcesQuery,
    setSelectedHostId,
    mutation,
    validationSchema,
    defaultValues: {
      id: -1,
      sourcePath: "",
      versionSourceHostId: -1,
      versionSourceHost: {
        id: -1,
        name: "",
        connectionString: "",
        containerName: ""
      }
    }
  };
}