import {Box, Button, CircularProgress} from "@mui/material";
import {useUpdateGroupFarmLinkButton} from "./useUpdateGroupFarmLinkButton";
import {UpdateGroupFarmDto} from "../../../models/domain/UpdateGroupFarmDto";
import {UpdateGroupDto} from "../../../models/domain/UpdateGroupDto";
import React from "react";

export type UpdateGroupFarmLinkButtonProps = {
  farm: UpdateGroupFarmDto;
  updateGroup: UpdateGroupDto | undefined;
}

export const UpdateGroupFarmLinkButton = ({farm, updateGroup}: UpdateGroupFarmLinkButtonProps) => {
  const {saveMutation: {mutateAsync, isLoading}} = useUpdateGroupFarmLinkButton(farm, updateGroup);

  return (
    <Box sx={{display: "flex"}} alignItems="center">
      {farm.updateGroup && farm.updateGroupId === updateGroup?.id &&
          <Button variant={"outlined"} size={"small"} color="warning" onClick={() => mutateAsync(false)}>Unlink</Button>}

      {(!farm.updateGroup || farm.updateGroupId !== updateGroup?.id) &&
          <Button variant={"outlined"} size={"small"} color="success" onClick={() => mutateAsync(true)}>Link</Button>}

      {isLoading && <CircularProgress size="20px" sx={{ml: 1}}/>}
    </Box>
  )
}