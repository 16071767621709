import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ApiResourcePaths } from "../../../apiRoutes";
import Constants from "../../../constants";
import axios from "axios";
import { useForm } from "../../../hooks/useForm";
import * as yup from "yup";
import { AppFormProps } from "../../../core/form/AppForm";
import { VersionSourceHostDto } from "../../../models/domain/VersionSourceHostDto";
import { useAuthContext } from "../../../hooks/useAuth";
import React from "react";
import { AdminAppRoutePaths } from "../../../routes";
import { useNavigate } from "react-router-dom";

const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  connectionString: yup.string().required("Connection string is required"),
  containerName: yup.string().required("Container name is required"),
})

export type NewVersionSourceHostDto = Omit<VersionSourceHostDto, "id">;

export const useNewVersionSourceHostProfile = (): AppFormProps<NewVersionSourceHostDto, VersionSourceHostDto> => {
  const formState = useForm(true);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {authHeader} = useAuthContext();

  const mutation = useMutation({
    mutationFn: async (values: NewVersionSourceHostDto): Promise<VersionSourceHostDto> => {
      const apiUrl = Constants.BACKEND_URL;
      return (await axios.post(ApiResourcePaths.versionSourceHosts(apiUrl), values, {
        headers: authHeader
      })).data;
    },
    onMutate: _ => {
      formState.setIsControlBlocked(true);
    },
    onSuccess: _ => {
      queryClient.invalidateQueries({
        queryKey: [ApiResourcePaths.versionSourceHosts()],
        exact: true
      });
      formState.setIsChangeAllowed(false);
    },
    onSettled: _ => {
      formState.setIsControlBlocked(false);
    }
  });

  const onSuccess = React.useCallback((_: any, data: VersionSourceHostDto) => {
    navigate(AdminAppRoutePaths.versionSourceHostId(data.id));
  }, [navigate]);

  return {
    ...formState,
    mutation,
    validationSchema,
    onSuccess,
    defaultValues: {
      name: "",
      connectionString: "",
      containerName: ""
    }
  };
}