import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent, DialogProps,
  DialogTitle,
} from "@mui/material";
import { DialogValueType } from "../../../models/dialog";
import { isEmptyChildren, isFunction } from "../../../core/utils";
import { AppDialogState } from "./useAppDialog";

export type AppDialogChildrenProps<TData extends DialogValueType, TExtraProps extends {} = {}> = {
  value: TData | null | undefined;
  setValue: (Value: TData | undefined) => void;
} & TExtraProps;

export type AppDialogProps<TData extends DialogValueType, TExtraProps extends {} = {}> =
  & AppDialogState<TData>
  & {
    dividers?: boolean;

    title?: React.ReactNode;
    actions?: React.ReactNode;

    children?: ((props: AppDialogChildrenProps<TData, TExtraProps>) => React.ReactNode) | React.ReactNode;
  }
  & Pick<DialogProps, "sx" | "id" | "maxWidth" | "keepMounted">
  & TExtraProps;

export const AppDialog = <TData extends DialogValueType, TExtraProps extends {} = {}>(props: AppDialogProps<TData, TExtraProps>) => {
  const {
    open,
    dividers,
    title,
    actions,
    children,
    sx,
    id,
    maxWidth,
    keepMounted
  } = props;

  return (
    <Dialog
      open={open}
      id={id}
      sx={sx}
      maxWidth={maxWidth}
      keepMounted={keepMounted}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers={dividers}>
        {
          isFunction(children)
            ? (children as (childrenProps: AppDialogChildrenProps<TData, TExtraProps>) => React.ReactNode)(
              {...props}
            )
            : !isEmptyChildren(children)
              ? React.Children.only(children)
              : null
        }
      </DialogContent>
      <DialogActions>
        {actions}
      </DialogActions>
    </Dialog>
  );
}
