import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ApiResourcePaths } from "../../../apiRoutes";
import Constants from "../../../constants";
import axios from "axios";
import { useForm } from "../../../hooks/useForm";
import * as yup from "yup";
import { AppFormProps } from "../../../core/form/AppForm";
import { UpdateGroupDto } from "../../../models/domain/UpdateGroupDto";
import { enumType } from "../../../core/validation/helpers";
import { UpdateStrategyDto } from "../../../models/domain/enums";
import { useNavigate } from "react-router-dom";
import React from "react";
import { AdminAppRoutePaths } from "../../../routes";
import { useAuthContext } from "../../../hooks/useAuth";

export type NewUpdateGroupDto = Omit<UpdateGroupDto, "id" | "isUpdateEnabled">;

const validationSchema = yup.object().shape({
  name: yup.string().required("Update group name is required"),
  updateStrategy: enumType(UpdateStrategyDto).required("Update strategy is required"),
})

export const useNewUpdateGroupProfile = (): AppFormProps<NewUpdateGroupDto, UpdateGroupDto> => {
  const formState = useForm(true);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {authHeader} = useAuthContext();

  const mutation = useMutation({
    mutationFn: async (values: NewUpdateGroupDto): Promise<UpdateGroupDto> => {
      const apiUrl = Constants.BACKEND_URL;
      return (await axios.post(ApiResourcePaths.updateGroups(apiUrl), values, {headers:authHeader})).data;
    },
    onMutate: _ => {
      formState.setIsControlBlocked(true);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [ApiResourcePaths.updateGroups()],
        exact: true
      });
      queryClient.invalidateQueries({
        queryKey: [ApiResourcePaths.updateGroups(null, data?.id)]
      });

      formState.setIsChangeAllowed(false);
    },
    onSettled: _ => {
      formState.setIsControlBlocked(false);
    }
  });

  const onSuccess = React.useCallback((_: any, data: UpdateGroupDto) => {
    navigate(AdminAppRoutePaths.updateGroupId(data.id));
  }, [navigate]);

  return {
    ...formState,
    mutation,
    validationSchema,
    onSuccess,
    defaultValues: {
      name: "",
      updateStrategy: UpdateStrategyDto.LatestStable
    }
  };
}