import React, { useMemo, useState } from "react";
import { MRT_ColumnDef, MRT_ColumnFiltersState, MRT_PaginationState, MRT_SortingState } from "material-react-table";
import { useQuery } from "@tanstack/react-query";
import { PageInfoDto } from "../../../models/domain/pagination/PageInfoDto";
import { ApiResourcePaths } from "../../../apiRoutes";
import Constants from "../../../constants";
import axios from "axios";
import { MrtDateTimeField } from "../../../comps/mrt/mrt-datetime/MrtDateTimeField";
import { LinkButton } from "../../../comps/link-button/LinkButton";
import { AdminAppRoutePaths } from "../../../routes";
import { ApiResourceTableProps } from "../../../comps/api-resource-table/ApiResourceTable";
import { VersionSourceHostDto } from "../../../models/domain/VersionSourceHostDto";
import { useAuthContext } from "../../../hooks/useAuth";

export const useVersionSourceHostList = (): ApiResourceTableProps<VersionSourceHostDto> => {
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const {authHeader} = useAuthContext();

  const query = useQuery<PageInfoDto<VersionSourceHostDto>>({
      queryKey: [
        ApiResourcePaths.versionSourceHosts(),
        columnFilters,
        globalFilter,
        pagination.pageIndex,
        pagination.pageSize,
        sorting,
      ],
      queryFn: async () => {
        const apiUrl = Constants.BACKEND_URL;
        const params = {
          page: pagination.pageIndex,
          pageSize: pagination.pageSize,
          filters: JSON.stringify(columnFilters ?? []),
          globalFilter: globalFilter ?? '',
          sorting: JSON.stringify(sorting ?? [])
        };

        return (await axios.get(ApiResourcePaths.versionSourceHosts(apiUrl), {
          params,
          headers: authHeader
        })).data;
      },
      keepPreviousData: true,
    });

  const columns = useMemo<MRT_ColumnDef<VersionSourceHostDto>[]>(
    () => {
      return [
        {
          accessorKey: 'name',
          header: 'Name',
          Cell: ({renderedCellValue, row}) => <LinkButton
            to={AdminAppRoutePaths.versionSourceHostId(row.original.id)}>{renderedCellValue}</LinkButton>
        },
        {
          accessorKey: 'containerName',
          header: 'Container Name',
        },
        {
          accessorKey: 'createdAt',
          header: 'Created',
          Cell: ({cell}) => <MrtDateTimeField cell={cell}/>
        },
        {
          accessorKey: 'updatedAt',
          header: 'Updated',
          Cell: ({cell}) => <MrtDateTimeField cell={cell}/>
        },
      ];
    },
    [],
  );

  return {
    columnFilters,
    setColumnFilters,
    globalFilter,
    setGlobalFilter,
    sorting,
    setSorting,
    pagination,
    setPagination,
    query,
    columns
  };
}