import Button, { ButtonProps } from "@mui/material/Button"
import { copyTextToClipboard } from "../../core/clipboard";
import { useState } from "react";
import { Popover, Typography } from "@mui/material";

interface CopyTextButtonProps extends ButtonProps<typeof Button> {
  text: string
}

export const CopyTextButton = ({ text, ...other}: CopyTextButtonProps) => {
  const [isCopied, setIsCopied] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleCopyClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);

    copyTextToClipboard(text)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button {...other} onClick={handleCopyClick}>{text}</Button>
      <Popover
        open={isCopied}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography sx={{ p: 2 }}>Copied!</Typography>
      </Popover>
    </>
  )
}