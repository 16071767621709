import { useMutation, useQuery, useQueryClient, UseQueryResult } from "@tanstack/react-query";
import { ApiResourcePaths } from "../../../apiRoutes";
import Constants from "../../../constants";
import axios from "axios";
import { useForm } from "../../../hooks/useForm";
import * as yup from "yup";
import { enumType } from "../../../core/validation/helpers";
import { ProductVersionStatusDto } from "../../../models/domain/enums";
import { AppFormWithQueryProps } from "../../../core/form/AppForm";
import { ProductVersionDto } from "../../../models/domain/ProductVersionDto";
import { PageInfoDto } from "../../../models/domain/pagination/PageInfoDto";
import { VersionSourceDto } from "../../../models/domain/VersionSourceDto";
import { useAuthContext } from "../../../hooks/useAuth";

export type VersionSourcesData = {
  data: PageInfoDto<VersionSourceDto>;
  itemsMap: Record<number, VersionSourceDto>;
}

export type ProductVersionProfileExtraProps = {
  versionSourcesQuery: UseQueryResult<VersionSourcesData>;
}

const validationSchema = yup.object().shape({
  name: yup.string().required("Farm name is required"),
  versionChannel: enumType(ProductVersionStatusDto).required("Status is required"),
  versionSourceId: yup.number().moreThan(0, "Version source is required").required("Version source is required"),
})

export const useProductVersionProfile = (id: number): AppFormWithQueryProps<ProductVersionDto, ProductVersionDto, ProductVersionProfileExtraProps> => {
  const formState = useForm();
  const queryClient = useQueryClient();
  const {authHeader} = useAuthContext();

  const primaryQuery = useQuery<ProductVersionDto>({
    queryKey: [
      ApiResourcePaths.productVersions(null, id),
    ],
    queryFn: async () => {
      const apiUrl = Constants.BACKEND_URL;
      return (await axios.get(ApiResourcePaths.productVersions(apiUrl, id), {headers: authHeader})).data;
    },
  });

  const versionSourcesQuery = useQuery<VersionSourcesData>({
    queryKey: [
      ApiResourcePaths.versionSources(null),
    ],
    queryFn: async () => {
      const apiUrl = Constants.BACKEND_URL;
      const data = (await axios.get(ApiResourcePaths.versionSources(apiUrl), {headers:authHeader})).data as PageInfoDto<VersionSourceDto>;

      const itemsMap = data.items.reduce((acc: Record<number, VersionSourceDto>, cur: VersionSourceDto) => {
        return {...acc, [cur.id]: cur}
      }, {});

      return {data, itemsMap};
    },
    keepPreviousData: true
  });

  const mutation = useMutation({
    mutationFn: async (values: ProductVersionDto): Promise<ProductVersionDto> => {
      const apiUrl = Constants.BACKEND_URL;
      return (await axios.patch(ApiResourcePaths.productVersions(apiUrl, id), values, {headers:authHeader})).data;
    },
    onMutate: _ => {
      formState.setIsControlBlocked(true);
    },
    onSuccess: _ => {
      queryClient.invalidateQueries({
        queryKey: [ApiResourcePaths.productVersions()],
        exact: true
      });
      queryClient.invalidateQueries({
        queryKey: [ApiResourcePaths.productVersions(null, id)]
      });
      formState.setIsChangeAllowed(false);
    },
    onSettled: _ => {
      formState.setIsControlBlocked(false);
    }
  });

  return {
    ...formState,
    primaryQuery,
    versionSourcesQuery,
    mutation,
    validationSchema,
    defaultValues: {
      id: -1,
      name: "",
      productVersionStatus: ProductVersionStatusDto.Unassigned,
      pipelineBuildTimestamp: null,
      metadata: null,
      versionSourceId: -1,
      versionSource: {
        id: -1,
        sourcePath: "",
        versionSourceHostId: -1,
        versionSourceHost: {
          id: -1,
          name: "",
          connectionString: "",
          containerName: ""
        }
      }
    }
  };
}