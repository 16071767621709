import React from 'react';
import { Divider, Stack, Toolbar, Typography } from "@mui/material";
import { useProductVersionList } from "./useProductVersionList";
import { ApiResourceTable } from "../../../comps/api-resource-table/ApiResourceTable";

const ProductVersionListPage = () => {
  const productVersionList = useProductVersionList();

  return (
    <Stack>
      <Toolbar>
        <Typography component="h1" variant="h5">
          Product versions
        </Typography>
        <Divider orientation="vertical" sx={{ ml: 1, mr: 1 }}/>
      </Toolbar>

      <Divider sx={{ mb: 2 }} />

      <ApiResourceTable {...productVersionList} />
    </Stack>
  );
}

export default ProductVersionListPage;
