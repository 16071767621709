import { Typography } from "@mui/material";
import {UpdateGroupDto} from "../../../models/domain/UpdateGroupDto";
import {ProductVersionDto} from "../../../models/domain/ProductVersionDto";

export type FarmLinkStatusProps = {
  farm: {
    updateGroup: UpdateGroupDto | null;
    productVersion: ProductVersionDto | null;
  }
}

export const FarmLinkStatus = ({farm}: FarmLinkStatusProps) => {
  if (!farm){
    return <></>
  }

  return (
    <Typography>
      {farm.updateGroup
        ? "Update group: " + farm.updateGroup.name
        : farm.productVersion
          ? "Explicit version: " + farm.productVersion.name
          : "No info"}
    </Typography>
  )
}