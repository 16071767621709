import { MutationOptions } from "@tanstack/react-query";
import * as yup from "yup";
import { AppFormProps } from "../../core/form/AppForm";
import { AuthSignInResponse, useAuthContext } from "../../hooks/useAuth";
import { useForm } from "../../hooks/useForm";

const validationSchema = yup.object().shape({
  username: yup.string().required("User name is required"),
  password: yup.string().required("Password is required"),
})

export type LoginFormData = {
  username: string;
  password: string;
}

export const useLogin = (): AppFormProps<LoginFormData, AuthSignInResponse | null> => {
  const formState = useForm(true);
  const { signInMutation: mutation } = useAuthContext();

  const mutationOptions: MutationOptions<AuthSignInResponse | null, unknown, LoginFormData> = {
    onMutate: _ => {
      formState.setIsControlBlocked(true);
    },
    onSuccess: _ => {
      formState.setIsChangeAllowed(false);
    },
    onSettled: _ => {
      formState.setIsControlBlocked(false);
    }
  };

  return {
    ...formState,
    mutation,
    mutationOptions,
    validationSchema,
    defaultValues: {
      username: "",
      password: "",
    }
  };
}