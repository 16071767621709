import React from 'react';
import {Button, CircularProgress, Divider, Skeleton, Stack, Toolbar, Typography} from "@mui/material";
import { useProductVersionProfile } from "./useProductVersionProfile";
import { useParams } from "react-router-dom";
import { ProductVersionProfileForm } from "./ProductVersionProfileForm";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Delete } from "@mui/icons-material";
import { ProductVersionIcon } from "../../../comps/icons/ProductVersionIcon";
import { useProductVersionFilesProfile } from "./useProductVersionFilesProfile";
import {ProductVersionFilesForm} from "./ProductVersionFilesForm";

const ProductVersionItemPage = () => {
  const {productVersionId} = useParams();
  const productVersionProfile = useProductVersionProfile(parseInt(productVersionId || ''));
  const {data, isFetching, isLoading, refetch} = productVersionProfile.primaryQuery;

  const productVersionFilesProfile = useProductVersionFilesProfile(data?.id);

  const onRefetch = React.useCallback(() => refetch(), [refetch]);

  return (
    <Stack>
      <Toolbar variant="dense">
        <Typography sx={{ mr: 1 }}>
          <ProductVersionIcon fontSize="large"/>
        </Typography>
        {
          isLoading
            ? <CircularProgress/>
            : <Typography component="h1" variant="h5" color="inherit">
                {data?.name}
              </Typography>
        }

        <Divider orientation="vertical" sx={{ ml: 2, mr: 2 }} flexItem />

        <Button variant="outlined" onClick={onRefetch} >
          <Typography>Refresh</Typography>
          { isFetching ? <CircularProgress size="20px" color="inherit" sx={{ml: 1}}/> : <RefreshIcon/>}
        </Button>

        <Button variant="outlined" color="warning" sx={{ml: 1}}
                disabled={isFetching || productVersionProfile.isChangeAllowed}>
          <Typography>Delete</Typography>
          <Delete/>
        </Button>
      </Toolbar>

      <Divider sx={{ mt: 2, mb: 2 }} />

      {
        isLoading || !data
          ? <Skeleton/>
          : <>
            <ProductVersionProfileForm {...productVersionProfile}/>
            <ProductVersionFilesForm {...productVersionFilesProfile} />
          </>
      }
    </Stack>
  );
}

export default ProductVersionItemPage;
