import { useMutation, useQuery, useQueryClient, UseQueryResult } from "@tanstack/react-query";
import { ApiResourcePaths } from "../../../apiRoutes";
import Constants from "../../../constants";
import axios from "axios";
import { useForm } from "../../../hooks/useForm";
import { PageInfoDto } from "../../../models/domain/pagination/PageInfoDto";
import { UpdateGroupDto } from "../../../models/domain/UpdateGroupDto";
import * as yup from "yup";
import { AppFormProps } from "../../../core/form/AppForm";
import { useAuthContext } from "../../../hooks/useAuth";
import { UpdateGroupUserDto } from "../../../models/domain/UpdateGroupUserDto";
import React, { useMemo, useState } from "react";
import { MRT_ColumnDef, MRT_ColumnFiltersState, MRT_PaginationState, MRT_SortingState } from "material-react-table";
import { ApiResourceTableProps } from "../../../comps/api-resource-table/ApiResourceTable";
import { UserAccessLevelSelect } from "../../../comps/update-group/user-access-level-select/UserAccessLevelSelect";

export type UpdateGroupUserAccessExtraProps = {
  usersQuery: UseQueryResult<PageInfoDto<UpdateGroupUserDto>>;
  usersList: ApiResourceTableProps<UpdateGroupUserDto>;
}

export type UpdateGroupUserFormDto = Pick<UpdateGroupUserDto, "userName">;

const validationSchema = yup.object().shape({
  userName: yup.string().required("User name is required"),
});

export const useUpdateGroupUserAccessProfile = (updateGroup: UpdateGroupDto | undefined): AppFormProps<UpdateGroupUserFormDto, UpdateGroupUserDto, UpdateGroupUserAccessExtraProps> => {
  const formState = useForm(true);
  const queryClient = useQueryClient();
  const {authHeader} = useAuthContext();

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const usersQuery = useQuery<PageInfoDto<UpdateGroupUserDto>>({
    queryKey: [
      ApiResourcePaths.updateGroupUsers(null, updateGroup?.id),
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    queryFn: async () => {
      const apiUrl = Constants.BACKEND_URL;
      const params = {
        page: pagination.pageIndex,
        pageSize: pagination.pageSize,
        filters: JSON.stringify(columnFilters ?? []),
        globalFilter: globalFilter ?? '',
        sorting: JSON.stringify(sorting ?? [])
      };

      return (await axios.get(ApiResourcePaths.updateGroupUsers(apiUrl, updateGroup?.id), {params, headers:authHeader})).data;
    },
    enabled: !!updateGroup?.id
  });

  const columns = useMemo<MRT_ColumnDef<UpdateGroupUserDto>[]>(
    () => {
      return [
        {
          accessorKey: 'userName',
          header: 'User Name',
        },
        {
          accessorKey: 'accessLevel',
          header: 'Access Level',
          Cell: ({row}) => <UserAccessLevelSelect updateGroupUser={row.original}/>
        },
      ];
    },
    [],
  );

  const mutation = useMutation({
    mutationFn: async (formDto: UpdateGroupUserFormDto): Promise<UpdateGroupUserDto> => {
      const apiUrl = Constants.BACKEND_URL;

      return (await axios.put(ApiResourcePaths.updateGroupUsers(apiUrl, updateGroup?.id),
        { userName: formDto.userName },
        {headers:authHeader})).data
    },
    onMutate: _ => {
      formState.setIsControlBlocked(true);
    },
    onSuccess: _ => {
      queryClient.invalidateQueries({
        queryKey: [ApiResourcePaths.updateGroupUsers(null, updateGroup?.id)]
      });
      formState.setIsChangeAllowed(true);
    },
    onSettled: _ => {
      formState.setIsControlBlocked(false);
    }
  });

  return {
    ...formState,
    defaultValues: {
      userName: ""
    },
    validationSchema,
    usersQuery,
    mutation,
    controls: {
      showCancel: false,
      showSave: false,
      showEdit: false
    },
    resetOnSuccess: true,
    usersList: {
      columnFilters,
      setColumnFilters,
      globalFilter,
      setGlobalFilter,
      sorting,
      setSorting,
      pagination,
      setPagination,
      query: usersQuery,
      columns
    }
  };
}