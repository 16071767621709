import { useMutation, useQuery, useQueryClient, UseQueryResult } from "@tanstack/react-query";
import { ApiResourcePaths } from "../../../apiRoutes";
import Constants from "../../../constants";
import axios from "axios";
import { useForm } from "../../../hooks/useForm";
import { PageInfoDto } from "../../../models/domain/pagination/PageInfoDto";
import { UpdateGroupDto } from "../../../models/domain/UpdateGroupDto";
import { FarmDto } from "../../../models/domain/FarmDto";
import * as yup from "yup";
import { AppFormProps } from "../../../core/form/AppForm";
import { UpdateStrategyDto } from "../../../models/domain/enums";
import { useAuthContext } from "../../../hooks/useAuth";

export type FarmUpdateGroupExtraProps = {
  groupsQuery: UseQueryResult<PageInfoDto<UpdateGroupDto>>;
}

export type FarmUpdateGroupFormDto = {
  updateGroup: UpdateGroupDto | null;
}

const validationSchema = yup.object().shape({
  updateGroup: yup.object().shape({
    id: yup.number().moreThan(0, "Update group is required").required("Update group is required"),
  }).nullable()
})

export const useFarmUpdateGroupProfile = (farm: FarmDto | undefined): AppFormProps<FarmUpdateGroupFormDto, FarmDto, FarmUpdateGroupExtraProps> => {
  const formState = useForm();
  const queryClient = useQueryClient();
  const {authHeader} = useAuthContext();

  const groupsQuery = useQuery<PageInfoDto<UpdateGroupDto>>({
    queryKey: [
      ApiResourcePaths.updateGroups(null),
    ],
    queryFn: async () => {
      const apiUrl = Constants.BACKEND_URL;
      return (await axios.get(ApiResourcePaths.updateGroups(apiUrl), {headers:authHeader})).data;
    }
  });

  const mutation = useMutation({
    mutationFn: async (formDto: FarmUpdateGroupFormDto): Promise<FarmDto> => {
      const apiUrl = Constants.BACKEND_URL;

      const updateGroupId = formDto?.updateGroup?.id;
      if (!updateGroupId) {
        return (await axios.delete(ApiResourcePaths.farmLinks(apiUrl, farm?.farmId), {headers:authHeader})).data;
      } else {
        return (await axios.put(ApiResourcePaths.farmLinks(apiUrl, farm?.farmId, updateGroupId), {headers:authHeader})).data;
      }
    },
    onMutate: _ => {
      formState.setIsControlBlocked(true);
    },
    onSuccess: _ => {
      queryClient.invalidateQueries({
        queryKey: [ApiResourcePaths.farms()],
        exact: true
      });
      queryClient.invalidateQueries({
        queryKey: [ApiResourcePaths.farms(null, farm?.farmId)]
      });
      formState.setIsChangeAllowed(false);
    },
    onSettled: _ => {
      formState.setIsControlBlocked(false);
    }
  });

  return {
    ...formState,
    defaultValues: {
      updateGroup: farm?.updateGroup || {
        id: -1,
        name: "",
        isUpdateEnabled: false,
        updateStrategy: UpdateStrategyDto.Pinned
      }
    },
    validationSchema,
    groupsQuery,
    mutation,
  };
}