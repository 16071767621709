import { MrtBaseFieldProps } from "../mrt-base/mrt-base";
import { Typography } from "@mui/material";
import React from "react";
import { ProductBrandDto } from "../../../models/domain/enums";
import { ProductBrandNames } from "../../../models/domain-enum-mapping";

export type MrtBrandFieldProps<T extends Record<string, any>> = MrtBaseFieldProps<T>

export const MrtBrandField = <T extends Record<string, any>,>({cell, naValue} : MrtBrandFieldProps<T>) => {
  const value = cell.getValue<ProductBrandDto>();
  const name = !!value && value in ProductBrandDto ? ProductBrandNames[value] : null;
  return (
    <Typography>{ name || naValue || ''}</Typography>
  );
}