import { useQuery } from "@tanstack/react-query";
import { FarmDto } from "../../../models/domain/FarmDto";
import { ApiResourcePaths } from "../../../apiRoutes";
import Constants from "../../../constants";
import axios from "axios";
import { useAuthContext } from "../../../hooks/useAuth";
import { AppFormOnlyQueryProps } from "../../../core/form/AppFormOnlyQuery";

export const useFarmProfile = (id: string): AppFormOnlyQueryProps<FarmDto> => {
  const {authHeader} = useAuthContext();

  const primaryQuery = useQuery<FarmDto>({
    queryKey: [
      ApiResourcePaths.farms(null, id),
    ],
    queryFn: async () => {
      const apiUrl = Constants.BACKEND_URL;
      return (await axios.get(ApiResourcePaths.farms(apiUrl, id), {headers:authHeader})).data;
    },
  });

  return {
    primaryQuery,
  };
}