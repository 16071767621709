import { format } from "date-fns";
import { Typography } from "@mui/material";
import React from "react";
import { MrtBaseFieldProps } from "../mrt-base/mrt-base";

export type MrtDateTimeFieldProps<T extends Record<string, any>> = MrtBaseFieldProps<T>

export const MrtDateTimeField = <T extends Record<string, any>,>({cell, naValue} : MrtDateTimeFieldProps<T>) => {
  const value = cell.getValue<number>();
  return (
    <Typography>{!!value ? format(new Date(value), "dd/MM/yyyy hh:mm") : (naValue || '')}</Typography>
  );
}