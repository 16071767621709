import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { useAuthContext } from "../../../hooks/useAuth";
import { FarmLinkKindDto } from "../../../models/domain/enums";
import Constants from "../../../constants";
import { ApiResourcePaths } from "../../../apiRoutes";
import React from "react";
import { FarmDto } from "../../../models/domain/FarmDto";
import { AppDialogControlState, useAppDialog } from "../../dialogs/dialog/useAppDialog";
import { DialogOutcome } from "../../../models/dialog";
import axios from "axios";
import { AppDialogProps } from "../../dialogs/dialog/AppDialog";
import { UpdateGroupDto } from "../../../models/domain/UpdateGroupDto";
import { ApiResourceTableState, useApiResourceTable } from "../../../hooks/useApiResourceTable";
import { useApiClient } from "../../../hooks/useApiClient";
import { FarmLinkDialogUpdateGroupColumnsFactory } from "./FarmLinkDialogUpdateGroupColumnsFactory";
import { ProductVersionDto } from "../../../models/domain/ProductVersionDto";
import { FarmLinkDialogProductVersionColumnsFactory } from "./FarmLinkDialogProductVersionColumnsFactory";

export type FarmLinkDialogValue = {
  linkKind: FarmLinkKindDto;
  linkId: number;
} | null;

export type FarmLinkDialogExtraProps = {
  farm: FarmDto;
  saveMutation:  UseMutationResult<FarmDto, unknown, FarmLinkDialogValue>;
  updateGroupsTableProps: ApiResourceTableState<UpdateGroupDto>;
  productVersionsTableProps: ApiResourceTableState<ProductVersionDto>;
}

export type FarmLinkDialogProps = AppDialogProps<FarmLinkDialogValue, FarmLinkDialogExtraProps>;

export const useFarmLinkDialog = (farm: FarmDto): FarmLinkDialogProps => {
  const queryClient = useQueryClient();
  const apiClient = useApiClient();
  const {authHeader} = useAuthContext();

  const saveMutation = useMutation({
    mutationFn: async (value: FarmLinkDialogValue): Promise<FarmDto> => {
      const apiUrl = Constants.BACKEND_URL;

      if (value === null) {
        return (await axios.delete(ApiResourcePaths.farmLinks(apiUrl, farm.farmId), {headers: authHeader})).data;
      }
      else {
        return (await axios.put(ApiResourcePaths.farmLinks(apiUrl, farm.farmId, value.linkKind, value.linkId),
          null, {headers: authHeader})).data;
      }
    },
    onSuccess: _ => {
      queryClient.invalidateQueries({
        queryKey: [ApiResourcePaths.farms(null)]
      });
      queryClient.invalidateQueries({
        queryKey: [ApiResourcePaths.farms(null, farm.farmId)]
      });
      queryClient.invalidateQueries({
        queryKey: [ApiResourcePaths.farmLinks(null, farm.farmId)]
      });
    }
  });

  const onClose = React.useCallback(async (state: AppDialogControlState, outcome: DialogOutcome, value: FarmLinkDialogValue | undefined) => {
    if (outcome === DialogOutcome.Ok && typeof value !== "undefined") {
      await saveMutation.mutateAsync(value);
    }

    if (outcome !== DialogOutcome.Undefined) {
      state.setOpen(false);
    }
  }, [saveMutation]);


  // ===== dialog props and effects =====
  const dialogProps = useAppDialog<FarmLinkDialogValue>({
    onClose: onClose
  })

  const {value, setValue} = dialogProps;

  React.useEffect(() => {
    const initialValue = farm.productVersionId == null && farm.updateGroupId == null
      ? null
      : {
        linkKind: farm.productVersionId !== null ? FarmLinkKindDto.ProductVersion : FarmLinkKindDto.UpdateGroup,
        linkId: farm.productVersionId !== null ? farm.productVersionId : farm.updateGroupId as number
      };

    setValue(initialValue);
  }, [farm.productVersionId, farm.updateGroupId, setValue]);

  // ===== update group table =====
  const [updateGroupRowSelection, setUpdateGroupRowSelection] = React.useState({});

  React.useEffect(() => {
    if (farm.updateGroupId != null) {
      setUpdateGroupRowSelection({[farm.updateGroupId]: true});
    }
  }, [farm.updateGroupId]);

  React.useEffect(() => {
    const keys = Object.keys(updateGroupRowSelection);
    if (keys.length === 0) return;

    const numericKey = parseInt(keys[0]);
    setValue({
      linkKind: FarmLinkKindDto.UpdateGroup,
      linkId: numericKey
    });
  }, [updateGroupRowSelection, setValue]);

  const updateGroupsTableProps: ApiResourceTableState<UpdateGroupDto> = {
    ...useApiResourceTable({
      requestFn: apiClient.updateGroup.list,
      queryKeyFn: ApiResourcePaths.updateGroups,
      columnFactory: FarmLinkDialogUpdateGroupColumnsFactory,
      queryOptions: {
        enabled: (value && value.linkKind === FarmLinkKindDto.UpdateGroup) ?? false,
        keepPreviousData: true
      }
    }),
    tableProps: {
      enableRowSelection: true,
      enableMultiRowSelection: false,
      onRowSelectionChange: setUpdateGroupRowSelection,
      getRowId: r => r?.id?.toString(),
      state: {
        rowSelection: updateGroupRowSelection,
        density: "compact"
      }
    }
  }

  // ===== product version table =====
  const [productVersionRowSelection, setProductVersionRowSelection] = React.useState({});

  React.useEffect(() => {
    if (farm.productVersionId != null) {
      setProductVersionRowSelection({[farm.productVersionId]: true});
    }
  }, [farm.productVersionId]);

  React.useEffect(() => {
    const keys = Object.keys(productVersionRowSelection);
    if (keys.length === 0) return;

    const numericKey = parseInt(keys[0]);
    setValue({
      linkKind: FarmLinkKindDto.ProductVersion,
      linkId: numericKey
    });
  }, [productVersionRowSelection, setValue]);

  const productVersionsTableProps: ApiResourceTableState<ProductVersionDto> = {
    ...useApiResourceTable({
      requestFn: apiClient.productVersion.list,
      queryKeyFn: ApiResourcePaths.productVersions,
      columnFactory: FarmLinkDialogProductVersionColumnsFactory,
      queryOptions: {
        enabled: (value && value.linkKind === FarmLinkKindDto.ProductVersion) || false,
        keepPreviousData: true
      },
      mrtParams: {
        initialSorting: [{id: "pipelineBuildTimestamp", desc: true}]
      }
    }),
    tableProps: {
      enableRowSelection: true,
      enableMultiRowSelection: false,
      onRowSelectionChange: setProductVersionRowSelection,
      getRowId: r => r?.id?.toString(),
      state: {
        rowSelection: productVersionRowSelection,
        density: "compact"
      }
    }
  }

  return {
    ...dialogProps,
    farm,
    saveMutation,
    updateGroupsTableProps,
    productVersionsTableProps
  }
}