import { ApiResourcePaths } from "../../../apiRoutes";
import { UpdateGroupDto } from "../../../models/domain/UpdateGroupDto";
import React from "react";
import { ApiResourceTableProps } from "../../../comps/api-resource-table/ApiResourceTable";
import { UpdateGroupFarmDto } from "../../../models/domain/UpdateGroupFarmDto";
import {IdExtraParams, useApiClient} from "../../../hooks/useApiClient";
import {useApiResourceTable} from "../../../hooks/useApiResourceTable";
import {UpdateGroupFarmsColumnsFactory} from "./UpdateGroupFarmsColumnsFactory";

export const useUpdateGroupFarmsProfile = (updateGroup: UpdateGroupDto | undefined): ApiResourceTableProps<UpdateGroupFarmDto> => {
  const apiClient = useApiClient();

  return {
    ...useApiResourceTable<UpdateGroupFarmDto, IdExtraParams>({
      requestFn: apiClient.updateGroup.farm.list,
      queryExtraParams: () => ({id: !!updateGroup?.id ? updateGroup.id : 0}),
      queryKeyFn: () => ApiResourcePaths.updateGroupFarms(null, updateGroup?.id),
      columnFactory: UpdateGroupFarmsColumnsFactory(updateGroup),
      queryOptions: {
        enabled: !!updateGroup?.id,
        keepPreviousData: true
      },
      mrtParams: {
        initialSorting: [{id: "updateGroupId", desc: true}]
      }
    }),
    tableProps: {
      state: {
        density: "compact"
      },
      manualSorting: true,
      manualFiltering: true,
      manualPagination: true,
    }
  };
}