import { MrtBaseFieldProps } from "../mrt-base/mrt-base";
import { Typography } from "@mui/material";
import React from "react";
import { ProductVersionStatusDto } from "../../../models/domain/enums";
import { ProductVersionStatusNames } from "../../../models/domain-enum-mapping";

export type MrtProductVersionStatusFieldProps<T extends Record<string, any>> = MrtBaseFieldProps<T>

export const MrtProductVersionStatusField = <T extends Record<string, any>,>({cell, naValue} : MrtProductVersionStatusFieldProps<T>) => {
  const value = cell.getValue<ProductVersionStatusDto>();
  const name = typeof(value) !== "undefined" && value in ProductVersionStatusDto ? ProductVersionStatusNames[value] : null;
  return (
    <Typography>{ name || naValue || ''}</Typography>
  );
}