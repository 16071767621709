import Button, { ButtonProps } from "@mui/material/Button"
import { Link as RouterLink, LinkProps } from "react-router-dom"

interface LinkButtonProps extends ButtonProps<typeof RouterLink, { component?: typeof RouterLink }> {
  to: LinkProps["to"]
}

export const LinkButton = ({to, ...other}: LinkButtonProps) =>
  (
    <Button {...other} component={RouterLink} to={to} />
  )