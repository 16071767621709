import {
  Autocomplete,
  CircularProgress,
  TextField,
} from "@mui/material";
import React from "react";
import { UpdateGroupProductVersionFormDto, FarmUpdateGroupExtraProps } from "./useUpdateGroupProductVersionProfile";
import { AppForm, AppFormProps } from "../../../core/form/AppForm";
import { UpdateGroupDto } from "../../../models/domain/UpdateGroupDto";

export const UpdateGroupProductVersionForm = (props: AppFormProps<UpdateGroupProductVersionFormDto, UpdateGroupDto, FarmUpdateGroupExtraProps>) => {
  const {data: productVersions, isFetching} = props.productVersionsQuery;
  const {isChangeAllowed} = props;

  const productVersionOptions = productVersions?.data?.items?.map(x => x.id) ?? []
  const options = [null, ...productVersionOptions];

  return (
    <AppForm {...props} formLabel="Product Version">
      {({values, touched, errors, handleBlur, setFieldValue}) => {
        return (
          <>
            <fieldset>
              <Autocomplete
                id="productVersion"
                disabled={!isChangeAllowed}
                options={options}
                getOptionLabel={option => {
                  return option === null
                    ? "No version"
                    : productVersions?.itemsMap && productVersions?.itemsMap[option]
                      ? productVersions?.itemsMap[option].name
                      : "N/A";
                }}
                getOptionDisabled={option => {
                  return option === values.productVersionId
                }}
                value={values.productVersionId}
                onChange={(_, value) => setFieldValue("productVersionId", value)}
                onBlur={handleBlur}
                renderInput={(params) =>
                  <TextField {...params}
                             variant="standard"
                             label="Selected Product Version"
                             onBlur={handleBlur}
                             error={touched.productVersionId && Boolean(errors.productVersionId)}
                             helperText={touched.productVersionId && errors.productVersionId}
                  />
                }
              />
              {isFetching && <CircularProgress sx={{ml: 2}}/>}
            </fieldset>
          </>
        )
      }
      }
    </AppForm>
  );
};