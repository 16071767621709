import React, { useState } from "react";
import { DialogOutcome, DialogValueType } from "../../../models/dialog";

export type AppDialogControlState = {
  open: boolean;
  setOpen: (value: boolean) => void;
}

export type AppDialogState<TData extends DialogValueType> =
  & AppDialogControlState
  & {
    value: TData | undefined;
    setValue: (value: TData | undefined) => void;

    handleClose: (outcome: DialogOutcome) => void;
    handleOpen: () => void;
  }

export type UseAppDialogParams<TData extends DialogValueType> =
  {
    initialOpen?: boolean;
    initialValue?: TData;
    onClose: (state: AppDialogControlState, outcome: DialogOutcome, value: TData | undefined) => void;
  };

export const useAppDialog = <TData extends DialogValueType>({
  initialValue,
  onClose,
  initialOpen = false
}: UseAppDialogParams<TData>): AppDialogState<TData> => {
  const [open, setOpen] = useState(initialOpen)
  const [value, setValue] = React.useState<TData | undefined>(initialValue);

  const handleClose = React.useCallback((outcome: DialogOutcome) => {
    onClose({open,setOpen}, outcome, value);
  }, [onClose, open, value]);

  const handleOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  return {
    open, setOpen,
    value, setValue,
    handleClose,
    handleOpen
  }
}