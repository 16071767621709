export enum ProductBrandDto
{
  Engs = 1,
  Abs = 2,
  Ingenuity = 3
}

export enum FileTargetBrandDto
{
  Common = 0,
  Engs = 1,
  Abs = 2,
  Ingenuity = 3
}

export enum ProductVersionStatusDto
{
  Unassigned = 0,
  Beta = 1,
  Stable = 2
}

export enum UpdateStrategyDto
{
  Pinned = 1,
  LatestStable = 2,
  LatestBeta = 3
}

export enum RoleDto
{
  Admin = 1,
  User = 2,
}

export enum UpdateGroupAccessLevelDto
{
  NoAccess = 0,
  View = 1,
  Edit = 2
}

export enum FarmLinkKindDto
{
  UpdateGroup = 1,
  ProductVersion = 2
}

export enum ProductVersionFileKeyDto
{
  Other = 0,
  Setup = 1,
  Updater = 2,
  Metadata = 3,
  Manifest = 4,
  Commit = 5,
  ReleaseNotes = 6,
  ReleaseNotesByCommit = 7
}