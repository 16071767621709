import React from 'react';
import { Divider, Stack, Toolbar, Typography } from "@mui/material";
import { useUpdateGroupList } from "./useUpdateGroupList";
import { ApiResourceTable } from "../../../comps/api-resource-table/ApiResourceTable";
import { LinkButton } from "../../../comps/link-button/LinkButton";
import { AdminAppRoutePaths } from "../../../routes";

const UpdateGroupListPage = () => {
  const updateGroupList = useUpdateGroupList();

  return (
    <Stack>
      <Toolbar>
        <Typography component="h1" variant="h5">
          Update Groups
        </Typography>
        <Divider orientation="vertical" sx={{ ml: 1, mr: 1 }}/>
        <LinkButton variant="contained" to={AdminAppRoutePaths.updateGroupNew()}>
          <Typography>Add new</Typography>
        </LinkButton>
      </Toolbar>

      <Divider sx={{ mb: 2 }} />

      <ApiResourceTable {...updateGroupList} />
    </Stack>
  );
}

export default UpdateGroupListPage;
