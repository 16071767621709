import { ProductVersionStatusDto } from "../../models/domain/enums";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ProductVersionDto } from "../../models/domain/ProductVersionDto";
import Constants from "../../constants";
import axios from "axios";
import { ApiResourcePaths } from "../../apiRoutes";
import { useAuthContext } from "../../hooks/useAuth";
import React from "react";

export type UseProductVersionStatusButtonState = {
  onClick: () => void;
}

export const useProductVersionStatusButton = (productVersionId: number, targetStatus: ProductVersionStatusDto): UseProductVersionStatusButtonState => {
  const queryClient = useQueryClient();
  const {authHeader} = useAuthContext();

  const mutation = useMutation({
    mutationFn: async (): Promise<ProductVersionDto> => {
      const apiUrl = Constants.BACKEND_URL;
      return (await axios.patch(ApiResourcePaths.productVersions(apiUrl, productVersionId, "status"), {
        ProductVersionStatus: targetStatus
      }, {headers: authHeader})).data;
    },
    onSuccess: _ => {
      queryClient.invalidateQueries({
        queryKey: [ApiResourcePaths.productVersions()]
      });
      queryClient.invalidateQueries({
        queryKey: [ApiResourcePaths.productVersions(null, productVersionId)]
      });
    }
  });

  const onClick = React.useCallback(async () => {
    await mutation.mutateAsync();
  }, [mutation]);

  return {
    onClick
  }
}