import React, { useState } from "react";
import { defaultIfUndefined } from "../core/utils";

export type UseSnackbarConfig = {
  initialOpen?: boolean,
  onClose?: () => void;
}

export const useSnackbar = ({initialOpen, onClose}: UseSnackbarConfig) => {
  const [open, setOpen] = useState(defaultIfUndefined(initialOpen, false));

  const openSnackbar = React.useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = React.useCallback((event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);

    if (onClose) {
      onClose();
    }
  }, [onClose]);

  return {
    setOpen,
    openSnackbar,
    snackbarProps: {
      open,
      onClose: handleClose,
    }
  }
}