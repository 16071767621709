import { mixed, object } from "yup";
import { FormikTouched } from "formik";

export const enumType = <T extends {}>(enumObject: { [s: string]: T } | ArrayLike<T>) => mixed<T>().oneOf(Object.values(enumObject));

export const makeInitialTouched = <T extends {}>(values: T): FormikTouched<T> => {
  return Object.fromEntries(
    Object.entries(values)
      .map(([key, value]) => {
        if (value instanceof object) {
          const nestedObject = makeInitialTouched(value);
          return [key, nestedObject];
        }
        return [key, true];
      })
  ) as FormikTouched<T>;
}