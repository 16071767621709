import {
  FarmLinkKindDto, FileTargetBrandDto,
  ProductBrandDto, ProductVersionFileKeyDto,
  ProductVersionStatusDto,
  RoleDto,
  UpdateGroupAccessLevelDto,
  UpdateStrategyDto
} from "./domain/enums";

export const ProductBrandNames: Record<ProductBrandDto, string> = {
  [ProductBrandDto.Abs]: 'ABS',
  [ProductBrandDto.Engs]: 'ENGS',
  [ProductBrandDto.Ingenuity]: 'Ingenuity',
}

export const FileTargetBrandNames: Record<FileTargetBrandDto, string> = {
  [FileTargetBrandDto.Common]: 'Common',
  [FileTargetBrandDto.Abs]: 'ABS',
  [FileTargetBrandDto.Engs]: 'ENGS',
  [FileTargetBrandDto.Ingenuity]: 'INGENUITY',
}

export const ProductVersionStatusNames: Record<ProductVersionStatusDto, string> = {
  [ProductVersionStatusDto.Unassigned]: 'Unassigned',
  [ProductVersionStatusDto.Beta]: 'Beta',
  [ProductVersionStatusDto.Stable]: 'Stable',
}

export const UpdateStrategyNames: Record<UpdateStrategyDto, string> = {
  [UpdateStrategyDto.Pinned]: 'Pinned',
  [UpdateStrategyDto.LatestBeta]: 'Latest Beta',
  [UpdateStrategyDto.LatestStable]: 'Latest Stable',
}

export const RoleNames: Record<RoleDto, string> = {
  [RoleDto.Admin]: 'Admin',
  [RoleDto.User]: 'User',
}

export const UpdateGroupAccessLevelNames: Record<UpdateGroupAccessLevelDto, string> = {
  [UpdateGroupAccessLevelDto.NoAccess]: 'No Access',
  [UpdateGroupAccessLevelDto.View]: 'View',
  [UpdateGroupAccessLevelDto.Edit]: 'Edit',
}

export const FarmLinkKindNames: Record<FarmLinkKindDto, string> = {
  [FarmLinkKindDto.ProductVersion]: 'Product Version',
  [FarmLinkKindDto.UpdateGroup]: 'Update Group',
}

export const ProductVersionFileKeyNames: Record<ProductVersionFileKeyDto, string> = {
  [ProductVersionFileKeyDto.Commit]: 'Commit info',
  [ProductVersionFileKeyDto.Manifest]: 'Manifest',
  [ProductVersionFileKeyDto.Metadata]: 'Metadata',
  [ProductVersionFileKeyDto.Other]: 'Other',
  [ProductVersionFileKeyDto.ReleaseNotes]: 'Release notes',
  [ProductVersionFileKeyDto.ReleaseNotesByCommit]: 'Release notes (by commit)',
  [ProductVersionFileKeyDto.Setup]: 'Full Setup',
  [ProductVersionFileKeyDto.Updater]: 'Updater',
}